import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoggerService } from '../services/logger-service/logger.service';
import { tap } from 'rxjs/operators';
import { getUser } from '../services/login-service/state/login-service.selector';
import { UserData } from '../dtos/response/login-response/user-data';
import { RootState } from '../state/state';
import { Store } from '@ngrx/store';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  private user: UserData;

  constructor(private loggerService: LoggerService, private store: Store<RootState>) {
    this.store.select(getUser).subscribe((user) => (this.user = user));
  }

  /**
   * Intercept any outgoing requests except for the api/caller-context requests and
   * log responses, only request url, status, status text and error (in case of error responses)
   * from the outgoing requests using the tap operator (tap operator doesn't have side effects to the returning observable stream).
   * More information on tap operator: https://indepth.dev/reference/rxjs/operators/tap
   * More information on Angular interceptor: https://angular.io/guide/http#intercepting-requests-and-responses
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if(event instanceof HttpResponse) {
            // filter out successful api calls to the caller-context endpoint
            if(!event.url.includes('caller-context')) {
              this.loggerService.log({
                url: event.url,
                method: request.method,
                status: event.status,
                statusText: event.statusText,
                sabreId: this.user?.username
              });
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          if (err.error?.reservation != null) {
            err.error.reservation = null;
          }

          this.loggerService.log({
            url: err.url,
            method: request.method,
            status: err.status,
            message: err.message,
            error: err.error,
            sabreId: this.user?.username
          });
        }
    }));
  }
}
