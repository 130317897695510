import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SavePassengerService } from '../save-passenger.service';
import {
  removePassengerData,
  removePassengerDataComplete,
  resetSaveLapInfantResponseInfo,
  saveLapInfantData,
  saveLapInfantDataComplete,
  savePassengerData,
  savePassengerDataComplete,
  setPassengerSaved,
  setRemovePassengerInfoType,
  updateLapInfantData,
} from './save-passenger.actions';
import { Store } from '@ngrx/store';
import { RootState } from '../../../state/state';
import { getPassengers, getRoutedConfirmationCode } from '../../reservation-service/state/reservation-service.selectors';
import { catchError, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SaveGuestMessageConverter } from '../../../utils/message-converters/save-guest/save-guest-message-converter';
import { addMultipleJetStreamMessagesNoDuplicate } from '../../message-service/state/message.actions';
import { MessageKey } from '../../../models/message/message-key';
import {
  removeInfant,
  resetPassengerContactTracingForm,
  resetPassengerEmergencyContactForm,
  resetPassengerInternationalDocumentsForm,
  setLapInfantLastSavedData,
  setPassengerContactTracingSaved,
  setPassengerEmergencyContactSaved,
  setPassengerInternationalDocumentsSaved,
  setPassengerLastSavedData,
  setPassengerMileagePlanSaved,
  setShowPassengerContactTracingForm,
  setShowPassengerEmergencyContactForm,
  setShowPassengerInternationalDocumentsForm,
} from '../../../shared/passenger-forms/state/passenger-forms.actions';
import { SavePassengerResponse } from '../../../dtos/response/save-passenger-response/save-passenger-response';
import { PassengerDataSlice } from '../../../dtos/response/save-passenger-response/passenger-data-slice';
import { RemovePassengerDataSlice } from '../../../dtos/request/save-passenger-request/remove-passenger-data-slice';
import { SavePassengerRequest } from '../../../dtos/request/save-passenger-request/save-passenger-request';
import { PassengerData } from '../../../models/passenger-forms/passenger-data';
import { SsrService } from '../../ssr-service/ssr.service';
import { AddSsrStatus } from '../../../dtos/response/ssr-response/add-ssr-status';
import { SaveLapInfantMessageConverter } from '../../../utils/message-converters/save-lap-infant/save-lap-infant-message-converter';
import { AddUpdateLapInfantRequest } from '../../../dtos/request/ssr-requests/add-lapInfant-request';
import { AddSsrsResponse } from '../../../dtos/response/ssr-response/add-ssrs-response';
import { LapInfantData } from '../../../models/passenger-forms/lap-infant-data';
import {
  addReservationMessage,
  loadReservationComplete,
  removePassengerLapInfant,
} from '../../reservation-service/state/reservation-service.actions';
import { NameNumberUtil } from '../../../utils/name-number-util';
import { mileagePlanAutoFillProfileSearch } from '../../mileage-plan-auto-fill-service/state/mileage-plan-auto-fill-service.actions';
import { convertDateFormats } from '../../../utils/date-time-util/date-time-util';
import { ReservationResponse } from 'src/app/dtos/response/reservation-response/reservation-response';
import { ReservationLookupStatus } from 'src/app/dtos/response/reservation-response/reservation-lookup-status';

@Injectable()
export class SavePassengerEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private savePassengerService: SavePassengerService,
    private ssrService: SsrService,
    private saveGuestConverter: SaveGuestMessageConverter,
    private saveLapInfantConverter: SaveLapInfantMessageConverter
  ) {}

  savePassengerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(savePassengerData),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([action, confirmationCode]) => {
        return this.savePassengerService.savePassengerInfo(action.request, confirmationCode).pipe(
          map((result) => {
            result = this.saveGuestConverter.convertSaveGuestErrorMessage(result);
            this.store.dispatch(
              addMultipleJetStreamMessagesNoDuplicate(result.mappedMessageKeys ?? [], undefined, {
                passengerId: action.request.nameReferenceNumber,
              })
            );
            this.handleSuccessfulSave(result, action.passengerHashId, action.request, confirmationCode);
            return savePassengerDataComplete(result, action.passengerHashId);
          }),
          catchError((err: HttpErrorResponse) => {
            this.store.dispatch(
              addMultipleJetStreamMessagesNoDuplicate([MessageKey.UNKNOWN_WITH_LINK], undefined, {
                passengerId: action.request.nameReferenceNumber,
              })
            );
            return of(savePassengerDataComplete({ statusCode: err.status, exceptionContent: err.message }, action.passengerHashId));
          })
        );
      })
    )
  );

  removePassengerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removePassengerData),
      withLatestFrom(this.store.select(getRoutedConfirmationCode), this.store.select(getPassengers)),
      mergeMap(([action, confirmationCode, passengers]) => {
        this.store.dispatch(setRemovePassengerInfoType(action.request.removePassengerDataSlice));
        // Note: Lap infant's error messages are identified by the lap infant's hashId, not the passenger's name reference number
        const idForErrorMessages =
          action.request?.removePassengerDataSlice === RemovePassengerDataSlice.LapInfant
            ? action.passengerHashId
            : action.request?.nameReferenceNumber;
        return this.savePassengerService.removePassengerInfo(action.request, confirmationCode).pipe(
          map((result) => {
            if (action.request.removePassengerDataSlice === RemovePassengerDataSlice.LapInfant) {
              const paxHashId = passengers.find((pax) =>
                NameNumberUtil.IsEquivalent(pax.id, action.request.nameReferenceNumber ?? '')
              )?.hashId;
              if (paxHashId) {
                this.store.dispatch(removePassengerLapInfant(confirmationCode, paxHashId));
              }
            }
            result = this.saveGuestConverter.convertSaveGuestErrorMessage(result, action.request.removePassengerDataSlice);
            this.store.dispatch(
              addMultipleJetStreamMessagesNoDuplicate(result.mappedMessageKeys ?? [], undefined, { passengerId: idForErrorMessages })
            );
            this.handleSuccessfulRemove(result, action.passengerHashId, action.request.removePassengerDataSlice, confirmationCode);
            this.handleFailureRemove(result, action.passengerHashId);
            return removePassengerDataComplete(result, action.passengerHashId);
          }),
          catchError((err: HttpErrorResponse) => {
            this.store.dispatch(
              addMultipleJetStreamMessagesNoDuplicate([MessageKey.UNKNOWN_WITH_LINK], undefined, { passengerId: idForErrorMessages })
            );
            return of(removePassengerDataComplete({ statusCode: err.status, exceptionContent: err.message }, action.passengerHashId));
          })
        );
      })
    )
  );

  /**
   * Triggered by addNewInfant action. Calls the ssrService.addUpdateLapInfants method, and depending on the result
   * return a addSsrFullSuccess, addSsrPartialSuccess, or addSsrFailure action
   */
  saveLapInfantData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveLapInfantData),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([action, confirmationCode]) => {
        // Call the lap infant endpoint
        return this.ssrService.addLapInfants(action.request, confirmationCode).pipe(
          map((result) => {
            // Handle error messages
            const mappedMessageKeys = this.saveLapInfantConverter.convertSaveLapInfantErrorMessage(result);
            this.store.dispatch(
              addMultipleJetStreamMessagesNoDuplicate(mappedMessageKeys ?? [], undefined, { passengerId: action?.passengerHashId })
            );
            this.handleSuccessfulLapInfantSave(result, action.passengerHashId, action.request, confirmationCode);
            // Set lap infant response for initial booking flow
            return saveLapInfantDataComplete(result, action.passengerHashId);
          }),
          catchError((err: HttpErrorResponse) => {
            this.store.dispatch(
              addMultipleJetStreamMessagesNoDuplicate([MessageKey.LAP_INFANT_UNKNOWN_WITH_LINK], undefined, {
                passengerId: action?.passengerHashId,
              })
            );
            return of(
              saveLapInfantDataComplete({ status: AddSsrStatus.SYSTEM_FAILURE, errorMessage: err.message }, action.passengerHashId)
            );
          })
        );
      })
    );
  });

  /**
   * Triggered by addNewInfant action. Calls the ssrService.addUpdateLapInfants method, and depending on the result
   * return a addSsrFullSuccess, addSsrPartialSuccess, or addSsrFailure action
   */
  updateLapInfantData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateLapInfantData),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([action, confirmationCode]) => {
        // Call the lap infant endpoint
        return this.ssrService.updateLapInfants(action.request, confirmationCode).pipe(
          map((result) => {
            // Handle error messages
            const mappedMessageKeys = this.saveLapInfantConverter.convertSaveLapInfantErrorMessage(result);
            this.store.dispatch(
              addMultipleJetStreamMessagesNoDuplicate(mappedMessageKeys ?? [], undefined, { passengerId: action?.passengerHashId })
            );
            this.handleSuccessfulLapInfantSave(result, action.passengerHashId, action.request, confirmationCode);
            // Set lap infant response for initial booking flow
            return saveLapInfantDataComplete(result, action.passengerHashId);
          }),
          catchError((err: HttpErrorResponse) => {
            this.store.dispatch(
              addMultipleJetStreamMessagesNoDuplicate([MessageKey.LAP_INFANT_UNKNOWN_WITH_LINK], undefined, {
                passengerId: action?.passengerHashId,
              })
            );
            return of(
              saveLapInfantDataComplete({ status: AddSsrStatus.SYSTEM_FAILURE, errorMessage: err.message }, action.passengerHashId)
            );
          })
        );
      })
    );
  });

  /**
   * Set state based on successful removal of forms
   * Set saved states so remove can be processed correctly
   */
  private handleSuccessfulSave(
    result: SavePassengerResponse,
    passengerHashId: string,
    request: SavePassengerRequest,
    confirmationCode: string
  ) {
    let passengerData: PassengerData = { passengerHashId };

    this.handleUpdatedReservationForSavePassenger(result, confirmationCode);

    const successList = result?.response?.successList?.map((success) => success.requestType) ?? [];
    // Build up the passenger data object with the data that was successfully saved
    if (successList.includes(PassengerDataSlice.BioInfo)) {
      passengerData = {
        ...passengerData,
        bio: {
          firstName: request.bioInfoRequest?.firstName,
          middleName: request.bioInfoRequest?.middleName,
          lastName: request.bioInfoRequest?.lastName,
          dateOfBirth: convertDateFormats(request.bioInfoRequest?.birthday),
          gender: request.bioInfoRequest?.gender,
        },
      };
    }

    if (successList.includes(PassengerDataSlice.MileagePlan)) {
      passengerData = {
        ...passengerData,
        mileagePlan: {
          mileageProgram: request.mileagePlanRequest?.[0]?.frequentFlyerCarrier,
          mileagePlanNumber: request.mileagePlanRequest?.[0]?.frequentFlyerNumber,
        },
      };
      if (request.mileagePlanRequest?.[0]?.frequentFlyerNumber) {
        this.store.dispatch(setPassengerMileagePlanSaved(passengerHashId, true, request.mileagePlanRequest?.[0]?.frequentFlyerNumber));
        this.store.dispatch(
          mileagePlanAutoFillProfileSearch(
            request.mileagePlanRequest?.[0]?.frequentFlyerNumber,
            passengerHashId,
            request.nameReferenceNumber ?? '',
            false
          )
        );
      }
    }
    if (!successList.includes(PassengerDataSlice.MileagePlan)) {
      // If mileage plan failed to save, remove the saved mileage plan from state
      this.store.dispatch(setPassengerMileagePlanSaved(passengerHashId, false, undefined));
    }

    if (successList.includes(PassengerDataSlice.KnownTraveler)) {
      passengerData = {
        ...passengerData,
        ktnRedress: {
          ...passengerData.ktnRedress,
          ktn: {
            number: request.knownTravelerRequest?.knownTravelerNumber,
            countryCode: request.knownTravelerRequest?.issueCountry,
          },
        },
      };
    }

    if (successList.includes(PassengerDataSlice.Redress)) {
      passengerData = {
        ...passengerData,
        ktnRedress: {
          ...passengerData.ktnRedress,
          redress: {
            number: request.redressRequest?.redressNumber,
            countryCode: request.redressRequest?.issueCountry,
          },
        },
      };
    }

    if (successList.includes(PassengerDataSlice.InternationalDocument)) {
      this.store.dispatch(setPassengerInternationalDocumentsSaved(passengerHashId, true));
      passengerData = {
        ...passengerData,
        internationalDocument: {
          countryOfCitizenship: request.internationalDocumentRequest?.[0]?.countryOfCitizenship,
          countryOfResidence: request.internationalDocumentRequest?.[0]?.countryOfResidence,
          documentType: request.internationalDocumentRequest?.[0]?.documentType,
          documentNumber: request.internationalDocumentRequest?.[0]?.documentNumber,
          expirationDate: convertDateFormats(request.internationalDocumentRequest?.[0]?.expirationDate),
        },
      };
      passengerData = {
        ...passengerData,
        bio: {
          firstName: request.internationalDocumentRequest?.[0]?.firstName,
          middleName: request.internationalDocumentRequest?.[0]?.middleName,
          lastName: request.internationalDocumentRequest?.[0]?.lastName,
          // Note: Use bioInfoRequest birthday for date string format consistency
          dateOfBirth: convertDateFormats(request.bioInfoRequest?.birthday),
          gender: request.internationalDocumentRequest?.[0]?.gender,
        },
      };
    }

    if (successList.includes(PassengerDataSlice.EmergencyContact)) {
      this.store.dispatch(setPassengerEmergencyContactSaved(passengerHashId, true));
      passengerData = {
        ...passengerData,
        emergencyContact: {
          firstName: request.emergencyContactRequest?.firstName,
          lastName: request.emergencyContactRequest?.lastName,
          phoneNumber: {
            phoneCountryCode: request.emergencyContactRequest?.country,
            phoneNumber: request.emergencyContactRequest?.phoneNumber,
          },
        },
      };
    }

    passengerData = this.handleSuccessfulContactTracingSave(passengerHashId, request, successList, passengerData);

    this.store.dispatch(setPassengerLastSavedData(passengerHashId, passengerData));
  }

  private handleSuccessfulContactTracingSave(
    passengerHashId: string,
    request: SavePassengerRequest,
    successList: PassengerDataSlice[],
    passengerData: PassengerData
  ): PassengerData {
    let contactTracingSaved = false;

    if (successList.includes(PassengerDataSlice.ContactTracingEmail)) {
      contactTracingSaved = true;
      passengerData = {
        ...passengerData,
        contactTracing: {
          ...passengerData.contactTracing,
          email: request.contactTracingRequest?.email,
        },
      };
    }
    if (successList.includes(PassengerDataSlice.ContactTracingPhone)) {
      contactTracingSaved = true;
      passengerData = {
        ...passengerData,
        contactTracing: {
          ...passengerData.contactTracing,
          phoneNumber: {
            phoneNumber: request.contactTracingRequest?.phoneNumber,
          },
        },
      };
    }
    if (successList.includes(PassengerDataSlice.ContactTracingAddress)) {
      contactTracingSaved = true;
      passengerData = {
        ...passengerData,
        contactTracing: {
          ...passengerData.contactTracing,
          address: request.contactTracingRequest?.address,
        },
      };
    }

    if (contactTracingSaved) {
      this.store.dispatch(setPassengerContactTracingSaved(passengerHashId, true));
    }

    return passengerData;
  }

  private handleSuccessfulRemove(
    result: SavePassengerResponse,
    passengerHashId: string,
    removePassengerDataSlice: RemovePassengerDataSlice,
    confirmationCode: string
  ) {
    this.handleUpdatedReservationForSavePassenger(result, confirmationCode);

    if (result?.response?.failedList?.length === 0 && !result?.exceptionContent) {
      switch (removePassengerDataSlice) {
        case RemovePassengerDataSlice.InternationalDocument:
          // Reset the passenger's international document form so the form is in it's empty state
          this.store.dispatch(resetPassengerInternationalDocumentsForm(passengerHashId));
          // Hide the international document form
          this.store.dispatch(setShowPassengerInternationalDocumentsForm(passengerHashId, false));
          break;
        case RemovePassengerDataSlice.EmergencyContact:
          // Reset the passenger's emergency contact form so the form is in it's empty state
          this.store.dispatch(resetPassengerEmergencyContactForm(passengerHashId));
          // Hide the emergency contact form
          this.store.dispatch(setShowPassengerEmergencyContactForm(passengerHashId, false));
          break;
        case RemovePassengerDataSlice.ContactTracing:
          // Reset the passenger's contact tracing form so the form is in it's empty state
          this.store.dispatch(resetPassengerContactTracingForm(passengerHashId));
          // Hide the contact tracing form
          this.store.dispatch(setShowPassengerContactTracingForm(passengerHashId, false));
          break;
        case RemovePassengerDataSlice.LapInfant:
          this.store.dispatch(resetSaveLapInfantResponseInfo(passengerHashId));
          this.store.dispatch(removeInfant(passengerHashId));
          break;
      }
    }
  }

  private handleFailureRemove(result: SavePassengerResponse, passengerHashId: string) {
    const failedList = result?.response?.failedList?.map((failure) => failure.requestType) ?? [];
    if (failedList.includes(PassengerDataSlice.BioInfo)) {
      // Note: If bio info failed to re-add, force a re-save for the entire form
      this.store.dispatch(setPassengerSaved(passengerHashId, false));
      if (failedList.length === 1) {
        // Note: If only bio info failed to re-add, we still want to hide the international doc form
        this.store.dispatch(setShowPassengerInternationalDocumentsForm(passengerHashId, false));
      }
    }
  }

  private handleSuccessfulLapInfantSave(
    result: AddSsrsResponse,
    infantHashId: string,
    request: AddUpdateLapInfantRequest,
    confirmationCode: string
  ) {
    let lapInfantData: LapInfantData = { passengerHashId: infantHashId };
    // Note: Each update to lap infant data will overwrite the previous data for each segments.
    // When at least 1 segment is successfully added, we can determine the next SAVE click to be an update.
    if (result?.successfullyAddedSsrs && result?.successfullyAddedSsrs?.length > 0) {
      lapInfantData = {
        ...lapInfantData,
        associatedPassengerNameNumber: request.passengerNumber,
        bio: {
          firstName: request.lapInfantDocInfo?.[0]?.firstName,
          middleName: request.lapInfantDocInfo?.[0]?.middleName,
          lastName: request.lapInfantDocInfo?.[0]?.lastName,
          dateOfBirth: convertDateFormats(request.lapInfantDocInfo?.[0]?.birthday),
          gender: request.lapInfantDocInfo?.[0]?.gender,
        },
      };
      this.store.dispatch(setLapInfantLastSavedData(infantHashId, lapInfantData));

      this.handleUpdatedReservationForLapInfant(result, confirmationCode);
    }
  }

  private handleUpdatedReservationForSavePassenger(result: SavePassengerResponse, confirmationCode: string) {
    // If the reservation was updated, update the reservation in the store
    if (result?.response?.updatedReservation) {
      const reservationResponse: ReservationResponse = {
        confirmationCode,
        reservation: result.response.updatedReservation,
        status: ReservationLookupStatus.SUCCESS,
      };
      this.store.dispatch(loadReservationComplete(reservationResponse));
    }
    // If the add/update/delete was successful, but the reservation was not updated, show a warning message
    else if (result.response.successList?.length > 0 && !result.response.updatedReservation) {
      this.store.dispatch(addReservationMessage(confirmationCode, MessageKey.GUEST_INFORMATION_RESERVATION_LOOKUP_FAILURE));
    }
  }

  private handleUpdatedReservationForLapInfant(result: AddSsrsResponse, confirmationCode: string) {
    if (result?.updatedReservation) {
      const reservationResponse: ReservationResponse = {
        confirmationCode,
        reservation: result.updatedReservation,
        status: ReservationLookupStatus.SUCCESS,
      };
      this.store.dispatch(loadReservationComplete(reservationResponse));
    } else if (result.successfullyAddedSsrs?.length > 0 && !result.updatedReservation) {
      this.store.dispatch(addReservationMessage(confirmationCode, MessageKey.GUEST_INFORMATION_RESERVATION_LOOKUP_FAILURE));
    }
  }
}
