import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, take, withLatestFrom } from 'rxjs/operators';
import { SeatMapLookupStatus } from '../../../dtos/response/seat-map-lookup-response/seat-map-lookup-status';
import { AssignSeatsStatus } from '../../../dtos/response/seats-save-status';
import {
  getCurrentSeatMapSegment,
  getCurrentSegmentHash,
  getCurrentSegmentIndex,
} from '../../../features/seat-map/state/seat-map.selectors';
import { setHasSavedSessionLevelChanges } from '../../../models/state/model.actions';
import { RootState } from '../../../state/state';
import { GlobalEvent, GlobalEventService } from '../../global-event-service/global-event.service';
import {
  getPassengers,
  getRoutedConfirmationCode,
  getRoutedReservation,
  getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes,
  getConfirmedSegments,
} from '../../reservation-service/state/reservation-service.selectors';
import { SeatsService } from '../seats.service';
import {
  assignSeatsComplete,
  reservationSeatMapLookup,
  saveSeatAssignments,
  reservationSeatMapLookupComplete,
  assignSeats,
} from './seats-service.actions';
import { syncSeats } from '../../reservation-service/state/reservation-service.actions';
import { SeatMapUtil } from '../../../utils/seat-map/seat-map-util';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { SeatMap } from '../../../models/seat-map/seat-map';
import { PassengerUseCase } from '../../../use-cases/passenger.use-case';
import { PassengerSeat } from '../../../dtos/response/reservation-response/passenger-seat';
import { SeatMapMessageConverter } from '../../../utils/message-converters/seat-map/seat-map-message-converter';
import { SeatAssignmentUtil } from '../../../utils/seat-map/seat-assignment-util';
import { SeatAssignmentMessageConverter } from '../../../utils/message-converters/seat-map/seat-assignment-message-converter';
import { setCurrentSegmentHashId } from '../../../features/seat-map/state/seat-map.actions';
import { SeatMapLookupRequest } from '../../../dtos/request/seat-map-lookup-request/seat-map-lookup-request';
import { getSeatMapLookupRequestTierStatuses } from './seats-service.selectors';

@Injectable()
export class SeatsServiceEffects {
  constructor(
    private actions$: Actions,
    private seatsService: SeatsService,
    private store: Store<RootState>,

    private globalEventService: GlobalEventService,
    private seatAssignmentUtil: SeatAssignmentUtil,
    private seatMapUtil: SeatMapUtil,
    private passengerUseCase: PassengerUseCase,
    private seatMapMessageConverter: SeatMapMessageConverter,
    private seatAssignmentMessageConverter: SeatAssignmentMessageConverter
  ) {}

  /**
   * Gets a seatmap for a flight on a reservation
   * Handles pre-reserved seats, passenger data, etc in post processing
   */
  reservationSeatMapLookup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reservationSeatMapLookup),
      withLatestFrom(
        this.store.select(getCurrentSegmentHash),
        this.store.select(getCurrentSeatMapSegment),
        this.store.select(getPassengers),
        this.store.select(getRoutedReservation),
        this.store.select(getAllSegmentsExcludingPastDatedARNKAndFilteredActionCodes),
        this.store.select(getSeatMapLookupRequestTierStatuses)
      ),
      mergeMap(([action, currentSegmentHashId, currentSeatMapSegment, passengers, routedReservation, filteredSegments, tierStatuses]) => {
        let updatedSeatMapLookupRequest: SeatMapLookupRequest;

        if (!currentSegmentHashId) {
          this.store.dispatch(setCurrentSegmentHashId(currentSeatMapSegment?.hashId));
        }

        if (tierStatuses.length > 0) {
          updatedSeatMapLookupRequest = {
            ...action.seatMapLookupRequest,
            tierStatuses,
          };
        } else {
          updatedSeatMapLookupRequest = action.seatMapLookupRequest;
        }

        return this.seatsService.seatMapLookup(updatedSeatMapLookupRequest, true).pipe(
          map((result) => {
            if (result.status === SeatMapLookupStatus.SUCCESS) {
              // Post process the seatmap to add passenger data, pre-reserved seats, etc
              const processedSeatMap = this.seatMapUtil.postProcessReservationSeatMap(
                result.response?.seatMap,
                action.seatMapLookupRequest?.preReservedSeats
              );

              // Update the reservation state with pre-reserved seats
              this.updateReservationStateSeats(processedSeatMap, passengers, action.seatMapLookupRequest?.segmentIndex);

              // Handle any non-error seatmap messages that need to be displayed
              this.seatMapMessageConverter.dispatchSeatMapMessages(
                processedSeatMap,
                action.seatMapLookupRequest?.segmentIndex,
                passengers,
                routedReservation,
                filteredSegments
              );
              result.processedSeatMap = processedSeatMap;
            } else {
              // Dispatch any error messages
              this.seatMapMessageConverter.mapSeatMapMessages(
                action.seatMapLookupRequest?.segmentIndex,
                result.status,
                result.errorMessage
              );
            }
            result.segmentIndex = action.seatMapLookupRequest?.segmentIndex;

            return reservationSeatMapLookupComplete(result);
          }),
          /**
           * When unknown errors happen from calling the service, instead of catching the error
           * inside outer stream (e.g., mergeMap, exhaustMap), catch error on inner stream, to keep the corresponding effect alive.
           * See NgRx effects error handling section for more details: https://v7.ngrx.io/guide/effects
           */
          catchError(() => {
            this.seatMapMessageConverter.mapSeatMapMessages(0, SeatMapLookupStatus.SYSTEM_FAILURE, null);
            return of(
              reservationSeatMapLookupComplete({
                success: false,
                status: SeatMapLookupStatus.SYSTEM_FAILURE,
              })
            );
          })
        );
      })
    )
  );

  /**
   * Calls seats service to assign seats for a segment on a reservation
   */
  assignSeats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assignSeats),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([action, confirmationCode]) => {
        return this.seatsService.assignSeats(confirmationCode, action.request).pipe(
          map((result) => {
            return assignSeatsComplete(result);
          })
        );
      }),
      catchError(() => {
        return of(assignSeatsComplete(AssignSeatsStatus.SYSTEM_FAILURE));
      })
    )
  );

  /**
   * Prior to 9/15/2022 this logic was handled in the seatmapsession. Migrated for SMS gut.
   * Calls seats service to save seat assignments from SeatMapComponent
   */
  saveSeatAssignments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSeatAssignments),
      withLatestFrom(
        this.store.select(getRoutedConfirmationCode),
        this.store.select(getPassengers),
        this.store.select(getCurrentSegmentIndex)
      ),
      mergeMap(([action, confirmationCode, passengers, segmentIndex]) => {
        // Clear any previous save error state, then reset purchasability
        this.seatMapMessageConverter.clearSeatMapSaveMessages();
        this.globalEventService.broadcast(GlobalEvent.RESET_PURCHASABILITY);

        // Diff the segments to create the smallest request to the web api for saving
        const assignSeatsRequest = this.seatAssignmentUtil.getSeatAssignmentDiff();
        return this.seatsService.assignSeats(confirmationCode, assignSeatsRequest).pipe(
          map((response: AssignSeatsStatus) => {
            if (response === AssignSeatsStatus.SUCCESS) {
              this.store.dispatch(syncSeats(confirmationCode));
              this.store.dispatch(setHasSavedSessionLevelChanges(true));
              this.globalEventService.blockedActionReason = null;
              this.globalEventService.broadcast(GlobalEvent.SEAT_ASSIGNMENT_CHANGES_PERSISTED, segmentIndex);
            }
            this.seatAssignmentMessageConverter.mapSeatAssignmentMessages(response);
            return assignSeatsComplete(response);
          })
        );
      }),
      catchError(() => {
        return of(assignSeatsComplete(AssignSeatsStatus.SYSTEM_FAILURE));
      })
    )
  );

  private updateReservationStateSeats(seatMap: SeatMap, passengers: Passenger[], segmentIndex: number): void {
    passengers?.forEach((passenger: Passenger) => {
      this.assignReservedSeats(seatMap, passenger, segmentIndex);
      passenger.extraSeatRefs?.forEach((extraSeatRef: Passenger) => this.assignReservedSeats(seatMap, extraSeatRef, segmentIndex));
    });
  }

  private assignReservedSeats(seatMap: SeatMap, passenger: Passenger, segmentIndex: number): void {
    let segmentHashId: string;
    this.store.pipe(select(getConfirmedSegments), take(1)).subscribe((segments) => (segmentHashId = segments[segmentIndex]?.hashId));
    if (passenger.seats?.[segmentIndex]) {
      if (this.passengerUseCase.hasSeatOnSegment(passenger, segmentHashId)) {
        const passengerSeat: PassengerSeat = passenger.seats[segmentIndex];
        const seatMapSeat = this.seatMapUtil.getSeat(seatMap, passengerSeat.row, passengerSeat.letter);
        if (seatMapSeat) {
          this.passengerUseCase.assignSeatInReservationState(passenger, seatMapSeat, segmentIndex, passengerSeat.extraSeatType);
          this.store.pipe(select(getRoutedConfirmationCode), take(1)).subscribe((confCode) => this.store.dispatch(syncSeats(confCode)));
        }
      }
    }
  }
}
