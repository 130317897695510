import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { AddUmnrRequest } from '../../dtos/request/ssr-requests/add-umnr-request';
import { AddOthsTeenRequest } from '../../dtos/request/ssr-requests/add-oths-teen-request';
import { AddWheelchairRequest } from '../../dtos/request/ssr-requests/add-wheelchair-request';
import { SsrDetail } from '../../dtos/request/ssr-requests/ssr-details';
import { AddSsrStatus } from '../../dtos/response/ssr-response/add-ssr-status';
import { AddSsrsDetailsResponse } from '../../dtos/response/ssr-response/add-ssrs-details-response';
import { AddSsrsResponse } from '../../dtos/response/ssr-response/add-ssrs-response';
import { RemoveSsrResponse } from '../../dtos/response/ssr-response/remove-ssr-response';
import { RemoveSsrStatus } from '../../dtos/response/ssr-response/remove-ssr-status';
import { SsrsResponseDetails } from '../../dtos/response/ssr-response/ssrs-response-details';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { NameNumberUtil } from '../../utils/name-number-util';
import { AddSvanRequest } from '../../dtos/request/ssr-requests/add-svan-request';
import { AddSsrWithRemarkRequest } from '../../dtos/request/ssr-requests/ssr-with-remark-request';
import { SsrConfigurations } from './state/ssr-configurations';
import { AddExstRequest } from '../../dtos/request/ssr-requests/add-exst-request';
import { AddCbbgRequest } from '../../dtos/request/ssr-requests/add-cbbg-request';
import { AddUpdateLapInfantRequest } from '../../dtos/request/ssr-requests/add-lapInfant-request';
import { SSRCode } from '../../models/ssr/ssr-code';
import { Passenger } from '../../dtos/response/reservation-response/passenger';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { AddPetRequest } from '../../dtos/request/ssr-requests/add-pet-request';
import { PetSsrInventoryStatus } from '../../dtos/response/ssr-response/pet-ssr-inventory-status';
import { BatchDeleteSsrResponse } from '../../dtos/response/ssr-response/batch-delete-ssr-response';
import { BatchDeleteSsrResponseDetail } from '../../dtos/response/ssr-response/batch-delete-ssr-response-detail';
import { MultiPetRequest } from '../../dtos/request/ssr-requests/multi-pets-request';
import { isInvalidSabreUserIdAndPass, isPasswordDecryptionError } from '../../utils/error-helper';

export interface SsrServiceAPI {
  addWheelchairs(request: AddWheelchairRequest, confirmationCode: string, ssrDetails: SsrDetail[]): Observable<AddSsrsResponse>;
  addUmnrs(request: AddUmnrRequest, confirmationCode: string, ssrDetails: SsrDetail[]): Observable<AddSsrsResponse>;
  addOthsTeens(request: AddOthsTeenRequest, confirmationCode: string, ssrDetails: SsrDetail[]): Observable<AddSsrsResponse>;
  removeSsr(confirmationCode: string, ssrId: string, ssrCode: string | null): Observable<RemoveSsrResponse>;
  removeSsrs(confirmationCode: string, ssrIds: string[]): Observable<RemoveSsrResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class SsrService implements SsrServiceAPI {
  constructor(private http: HttpClient, private eventService: GlobalEventService) {}

  /**
   * Add one or more wheelchair ssrs to a reservation for one or more passengers on one or more flights
   */
  addWheelchairs(request: AddWheelchairRequest, confirmationCode: string, ssrDetails: SsrDetail[]): Observable<AddSsrsResponse> {
    return this.http.post<AddSsrsDetailsResponse>(`api/reservation/${confirmationCode}/ssr/wheelchairs`, request).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapResponse(response, ssrDetails)),
      catchError((err) => this.mapError(err, ssrDetails))
    );
  }

  /**
   * Add one or more umnr ssrs to a reservation for one or more passengers on one or more flights
   */
  addUmnrs(request: AddUmnrRequest, confirmationCode: string, ssrDetails: SsrDetail[]): Observable<AddSsrsResponse> {
    return this.http.post<AddSsrsDetailsResponse>(`api/reservation/${confirmationCode}/ssr/umnrs`, request).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapResponse(response, ssrDetails)),
      catchError((err) => this.mapError(err, ssrDetails))
    );
  }

  /**
   * Add one or more ssrs with remark to a reservation for one or more passengers on one or more flights
   */
  addSsrsWithRemark(
    request: AddSsrWithRemarkRequest,
    confirmationCode: string,
    ssrDetails: SsrDetail[],
    ssrSelectedOption: string
  ): Observable<AddSsrsResponse> {
    const ssrUrlFragment = SsrConfigurations.getSsrUrlFragment(ssrSelectedOption);
    return this.http.post<AddSsrsDetailsResponse>(`api/reservation/${confirmationCode}/ssr/${ssrUrlFragment}`, request).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapResponse(response, ssrDetails)),
      catchError((err) => this.mapError(err, ssrDetails))
    );
  }

  /**
   * Add one or more svan ssrs to a reservation for one or more passengers on one or more flights
   */
  addSvans(request: AddSvanRequest, confirmationCode: string, ssrDetails: SsrDetail[]): Observable<AddSsrsResponse> {
    return this.http.post<AddSsrsDetailsResponse>(`api/reservation/${confirmationCode}/ssr/svans`, request).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapResponse(response, ssrDetails)),
      catchError((err) => this.mapError(err, ssrDetails))
    );
  }

  /**
   * Add one or more exst ssrs to a reservation for one or more passengers on one or more flights
   */
  addExsts(request: AddExstRequest, confirmationCode: string, ssrDetails: SsrDetail[]): Observable<AddSsrsResponse> {
    return this.http.post<AddSsrsDetailsResponse>(`api/reservation/${confirmationCode}/ssr/exsts`, request).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapResponse(response, ssrDetails)),
      catchError((err) => this.mapError(err, ssrDetails))
    );
  }

  /**
   * Add one or more cbbg ssrs to a reservation for one or more passengers on one or more flights
   */
  addCbbg(request: AddCbbgRequest, confirmationCode: string, ssrDetails: SsrDetail[]): Observable<AddSsrsResponse> {
    return this.http.post<AddSsrsDetailsResponse>(`api/reservation/${confirmationCode}/ssr/cbbgs`, request).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapResponse(response, ssrDetails)),
      catchError((err) => this.mapError(err, ssrDetails))
    );
  }

  /**
   * Add one or more OTHS/TEEN ssrs to a reservation for one or more passengers on one or more flights
   */
  addOthsTeens(request: AddOthsTeenRequest, confirmationCode: string, ssrDetails: SsrDetail[]): Observable<AddSsrsResponse> {
    return this.http.post<AddSsrsDetailsResponse>(`api/reservation/${confirmationCode}/ssr/oths/teen`, request).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapResponse(response, ssrDetails)),
      catchError((err) => this.mapError(err, ssrDetails))
    );
  }

  /**
   * Add one or more Lap Infant Ssrs to a reservation for one passenger on one or more flights
   */
  addLapInfants(request: AddUpdateLapInfantRequest, confirmationCode: string): Observable<AddSsrsResponse> {
    return this.http.post<AddSsrsDetailsResponse>(`api/reservation/${confirmationCode}/ssr/lapinfant`, request).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapResponse(response, this.getLapInfantSsrsDetails(request))),
      catchError((err) => this.mapError(err, this.getLapInfantSsrsDetails(request)))
    );
  }

  /**
   * Add one or more Pet Ssrs to a reservation for one passenger on one or more flights
   */
  addPetsSsrs(request: MultiPetRequest, confirmationCode: string): Observable<AddSsrsResponse> {
    return this.http.post<AddSsrsDetailsResponse>(`api/reservation/${confirmationCode}/ssr/pets`, request).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapPetsSsrResponse(response, request)),
      catchError((err) => this.mapError(err, this.getPetsSsrsDetails(request, PetSsrInventoryStatus.UNCONFIRMED_SSR_NOT_ADDED)))
    );
  }

  /**
   * Update one or more Lap Infant Ssrs to a reservation for one passenger on one or more flights
   */
  updateLapInfants(request: AddUpdateLapInfantRequest, confirmationCode: string): Observable<AddSsrsResponse> {
    return this.http.put<AddSsrsDetailsResponse>(`api/reservation/${confirmationCode}/ssr/lapinfant`, request).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapResponse(response, this.getLapInfantSsrsDetails(request))),
      catchError((err) => this.mapError(err, this.getLapInfantSsrsDetails(request)))
    );
  }

  /**
   * Remove a single SSR from a reservation given the confirmation code and ssrId
   */
  removeSsr(confirmationCode: string, ssrId: string, ssrCode: string | null = null): Observable<RemoveSsrResponse> {
    return this.http.delete<RemoveSsrResponse>(`api/reservation/${confirmationCode}/ssr?ssrId=${ssrId}`).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => {
        // Null response = success
        if (response === null) {
          return { status: RemoveSsrStatus.SUCCESS, successfullyRemovedSsrId: ssrId };
        } else {
          return { status: RemoveSsrStatus.SYSTEM_FAILURE };
        }
      }),
      catchError((err) => {
        if (ssrCode !== null && (ssrCode === SSRCode.AVIH || ssrCode === SSRCode.PETC)) {
          switch (true) {
            case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase().includes('timeout')):
            case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase().includes('remark not removed')):
            case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase().includes('service error')):
            case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase().includes('an error occurred while sending the request')):
            case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase().includes('reservation lookup failure')):
            case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase().includes('update reservation processing error')):
              return of({ status: RemoveSsrStatus.PET_SSR_GENERAL_ERROR });
            case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase().includes('et processing error past date pnr - changes not allowed')):
              return of({ status: RemoveSsrStatus.PET_SSR_PAST_DATED_PNR_ERROR });
            case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase().includes('simos')):
            case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase().includes('et processing error pnr has been updated - ign and retry')):
            case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase().includes('et processing error simultaneous changes to pnr-use ir to ignore and retrieve pnr')):
              return of({ status: RemoveSsrStatus.PET_SSR_SIMUTANEOUS_CHANGES_ERROR });
            default:
              return of({ status: RemoveSsrStatus.PET_SSR_UNKNOWN_ERROR });
          }
        }
        else {
          if (timeoutError.statusText === err.statusText) {
            this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
            return of({
              status: RemoveSsrStatus.TIMEOUT,
              errorMessage: 'Timeout'
            });
          }
          else if(typeof err.error === 'string' && (isPasswordDecryptionError(err.error) || isInvalidSabreUserIdAndPass(err.error))) {
            return of({
              status: RemoveSsrStatus.PASSWORD_DECRYPTION_ERROR,
              errorMessage: 'password decryption error',
            });
          }
          return of({
            status: RemoveSsrStatus.SYSTEM_FAILURE,
            errorMessage: 'Request failed, the SSR was not removed from the reservation',
          });
        }
      })
    );
  }

  removeSsrs(confirmationCode: string, ssrIds: string[]): Observable<RemoveSsrResponse> {
    return this.http.post<RemoveSsrResponse>(`api/reservation/${confirmationCode}/ssr/remove`, ssrIds).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => {
        // Null response = success
        if (response === null) {
          return { status: RemoveSsrStatus.SUCCESS, successfullyRemovedSsrIds: ssrIds };
        } else {
          return { status: RemoveSsrStatus.SYSTEM_FAILURE };
        }
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          return of({ status: RemoveSsrStatus.TIMEOUT, errorMessage: 'Timeout' });
        } else {
          return of({
            status: RemoveSsrStatus.SYSTEM_FAILURE,
            errorMessage: 'Request failed, the SSR was not removed from the reservation',
          });
        }
      })
    );
  }

  batchDeleteSsrs(confirmationCode: string, ssrIds: string[]): Observable<BatchDeleteSsrResponse> {
    return this.http.post<BatchDeleteSsrResponse>(`api/reservation/${confirmationCode}/ssr/batch-delete`, { ssrIds }).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => {
        return response;
      }),
      catchError((err) => {
        let errorMessage = err.error ?? err.statusText;
        if (timeoutError.statusText === err.statusText) {
          errorMessage = 'Timeout';
        }
        this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
        return this.mapBatchDeleteSsrsErrorResponse(ssrIds, errorMessage);
      })
    );
  }

  /**
   * Map the wheelchair ssrs to the full ssr details whether they were successful or not.
   * Full details are required for the ssr summary page
   */
  private getSsrsDetails(ssrDetails: SsrDetail[] | undefined, ssrResponseDetails: SsrsResponseDetails[] | undefined): SsrDetail[] {
    const addedSsrs: SsrDetail[] = [];
    ssrResponseDetails?.forEach((ssrResponseDetail) => {
      const re = /0/gi;
      const matchingDetails = ssrDetails?.filter(
        (ssrDetail) =>
          ssrDetail.ssrCode === ssrResponseDetail.ssrCode &&
          (NameNumberUtil.IsEquivalent(ssrDetail.passengerDetails.id, ssrResponseDetail.passengerNumber) ||
            ssrDetail.passengerDetails.extraSeatRefs?.map((e) => e.id.replace(re, '')).includes(ssrResponseDetail.passengerNumber)) &&
          ssrDetail.segmentDetails.segmentHashId === ssrResponseDetail.segmentHashId
      );
      if (matchingDetails?.length) {
        const matchingDetail: SsrDetail = {
          ...matchingDetails[0],
        };
        matchingDetail.failureMessage = ssrResponseDetail.failureMessage;
        matchingDetail.cannedFreeText = ssrResponseDetail.cannedFreeText;
        matchingDetail.ssrCode = ssrResponseDetail.ssrCode;
        matchingDetail.ssrId = ssrResponseDetail.ssrId;
        addedSsrs.push(matchingDetail);
      }
    });

    return addedSsrs;
  }

  /**
   * @param request Original Lap infant request
   * @returns Mapped SsrDetails array containing falied and success ssrs
   */
  private getLapInfantSsrsDetails(request: AddUpdateLapInfantRequest): SsrDetail[] {
    const lapInfantSsrDetails: SsrDetail[] = [];
    request.lapInfantDocInfo.forEach((lapInfant) => {
      request.segmentDetails.forEach((segment) => {
        lapInfantSsrDetails.push({
          passengerDetails: {
            // Attached passenger id
            id: request.passengerNumber,
            secureFlightInfo: {
              firstName: lapInfant.firstName,
              lastName: lapInfant.lastName,
              dateOfBirth: lapInfant.birthday,
              gender: lapInfant.gender,
              middleName: lapInfant.middleName,
            },
          } as Passenger,
          segmentDetails: segment,
          ssrCode: SSRCode.INFT,
        });
      });
    });
    return lapInfantSsrDetails;
  }

  private getPetSsrsDetails(request: AddPetRequest, petSsrInventoryStatus = PetSsrInventoryStatus.PENDING): SsrDetail[] {
    const petSsrsDetails: SsrDetail[] = [];
    request.segmentDetails.forEach((segment) => {
      petSsrsDetails.push({
        ssrCode: request.ssrCode,
        segmentDetails: segment,
        passengerDetails: {
          id: request.passengerNumber,
        } as any as Passenger,
        petInventoryStatus: petSsrInventoryStatus
      });
    });

    return petSsrsDetails;
  }

  private getPetsSsrsDetails(request: MultiPetRequest, petSsrInventoryStatus = PetSsrInventoryStatus.PENDING): SsrDetail[] {
    const petSsrsDetails: SsrDetail[] = [];
    request.segmentDetails.forEach((segment) => {
      request.passengerPetRequests.forEach(passengerPetRequest => {
        passengerPetRequest.petRequests.forEach(petRequest => {
          petSsrsDetails.push({
            ssrCode: petRequest.ssrCode,
            segmentDetails: segment,
            passengerDetails: {
              id: passengerPetRequest.nameReferenceNumber,
              firstName: passengerPetRequest.passengerFirstName,
              lastName: passengerPetRequest.passengerLastName,
            } as any as Passenger,
            petInventoryStatus: petSsrInventoryStatus
          });
        });
      });
    });

    return petSsrsDetails;
  }

  private mapResponse(response: AddSsrsDetailsResponse, ssrDetails: SsrDetail[] | undefined): AddSsrsResponse {
    if (response.failedSsrs?.length) {
      return {
        status: AddSsrStatus.PARTIAL_SUCCESS,
        failedToAddSsrs: this.getSsrsDetails(ssrDetails, response.failedSsrs),
        successfullyAddedSsrs: this.getSsrsDetails(ssrDetails, response.successSsrs),
        updatedReservation: response.updatedReservation,
      };
    }
    return {
      status: AddSsrStatus.SUCCESS,
      successfullyAddedSsrs: this.getSsrsDetails(ssrDetails, response.successSsrs),
      updatedReservation: response.updatedReservation,
    };
  }

  private mapPetSsrResponse(response: AddSsrsDetailsResponse, request: AddPetRequest): AddSsrsResponse {
    if (!response.remarksAddedSuccessfully) {
      return {
        status: AddSsrStatus.PET_REMARKS_ADD_FAILURE,
        failedToAddSsrs: this.getSsrsDetails(
          this.getPetSsrsDetails(request, PetSsrInventoryStatus.UNCONFIRMED_SSR_NOT_ADDED), response.failedSsrs),
        errorMessage: 'Request failed, none of the SSRs were added to the reservation',
        isRemarksAddedSuccessfully: response.remarksAddedSuccessfully,
      };
    }

    if (!response.queueAddedSuccessfully) {
      return {
        ...this.mapResponse(response, this.getPetSsrsDetails(request, PetSsrInventoryStatus.UNCONFIRMED_SSR_ADDED)),
        status: AddSsrStatus.PET_QUEUE_FAILURE,
        isQueueAddedSucessfully: response.queueAddedSuccessfully,
        isRemarksAddedSuccessfully: response.remarksAddedSuccessfully
      };
    }

    let status = AddSsrStatus.FULL_FAILURE;
    let failedToAddSsrs: SsrDetail[] = [];
    let successfullyAddedSsrs: SsrDetail[] = [];

    if (response.successSsrs?.length && response.failedSsrs?.length) {
      status = AddSsrStatus.PARTIAL_SUCCESS;
      failedToAddSsrs = this.getSsrsDetails(
        this.getPetSsrsDetails(request, PetSsrInventoryStatus.UNCONFIRMED_SSR_NOT_ADDED), response.failedSsrs);
      successfullyAddedSsrs = this.getSsrsDetails(
        this.getPetSsrsDetails(request, PetSsrInventoryStatus.UNCONFIRMED_SSR_ADDED), response.successSsrs);
    }
    else if (response.successSsrs?.length && !response.failedSsrs?.length) {
      status = AddSsrStatus.SUCCESS;
      successfullyAddedSsrs = this.getSsrsDetails(
        this.getPetSsrsDetails(request, PetSsrInventoryStatus.PENDING), response.successSsrs);
    }
    else if (!response.successSsrs?.length && response.failedSsrs?.length) {
      status = AddSsrStatus.FULL_FAILURE;
      failedToAddSsrs = this.getSsrsDetails(
        this.getPetSsrsDetails(request, PetSsrInventoryStatus.UNCONFIRMED_SSR_NOT_ADDED), response.failedSsrs);
    }

    return {
      status,
      failedToAddSsrs,
      successfullyAddedSsrs,
      isQueueAddedSucessfully: response.queueAddedSuccessfully,
      isRemarksAddedSuccessfully: response.remarksAddedSuccessfully
    };
  }

  private mapPetsSsrResponse(response: AddSsrsDetailsResponse, request: MultiPetRequest): AddSsrsResponse {
    if (!response.remarksAddedSuccessfully) {
      return {
        status: AddSsrStatus.PET_REMARKS_ADD_FAILURE,
        failedToAddSsrs: this.getSsrsDetails(
          this.getPetsSsrsDetails(request, PetSsrInventoryStatus.UNCONFIRMED_SSR_NOT_ADDED), response.failedSsrs),
        errorMessage: 'Request failed, none of the SSRs were added to the reservation',
        isRemarksAddedSuccessfully: response.remarksAddedSuccessfully,
      };
    }

    if (!response.queueAddedSuccessfully) {
      return {
        ...this.mapResponse(response, this.getPetsSsrsDetails(request, PetSsrInventoryStatus.UNCONFIRMED_SSR_ADDED)),
        status: AddSsrStatus.PET_QUEUE_FAILURE,
        isQueueAddedSucessfully: response.queueAddedSuccessfully,
        isRemarksAddedSuccessfully: response.remarksAddedSuccessfully
      };
    }

    let status = AddSsrStatus.FULL_FAILURE;
    let failedToAddSsrs: SsrDetail[] = [];
    let successfullyAddedSsrs: SsrDetail[] = [];

    if (response.successSsrs?.length && response.failedSsrs?.length) {
      status = AddSsrStatus.PARTIAL_SUCCESS;
      failedToAddSsrs = this.getSsrsDetails(
        this.getPetsSsrsDetails(request, PetSsrInventoryStatus.UNCONFIRMED_SSR_NOT_ADDED), response.failedSsrs);
      successfullyAddedSsrs = this.getSsrsDetails(
        this.getPetsSsrsDetails(request, PetSsrInventoryStatus.UNCONFIRMED_SSR_ADDED), response.successSsrs);
    }
    else if (response.successSsrs?.length && !response.failedSsrs?.length) {
      status = AddSsrStatus.SUCCESS;
      successfullyAddedSsrs = this.getSsrsDetails(
        this.getPetsSsrsDetails(request, PetSsrInventoryStatus.PENDING), response.successSsrs);
    }
    else if (!response.successSsrs?.length && response.failedSsrs?.length) {
      status = AddSsrStatus.FULL_FAILURE;
      failedToAddSsrs = this.getSsrsDetails(
        this.getPetsSsrsDetails(request, PetSsrInventoryStatus.UNCONFIRMED_SSR_NOT_ADDED), response.failedSsrs);
    }

    return {
      status,
      failedToAddSsrs,
      successfullyAddedSsrs,
      isQueueAddedSucessfully: response.queueAddedSuccessfully,
      isRemarksAddedSuccessfully: response.remarksAddedSuccessfully
    };
  }

  private mapError(err: any, ssrDetails: SsrDetail[]): Observable<AddSsrsResponse> {
    // 1. Check for timeout error
    if (timeoutError.statusText === err.statusText) {
      this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
      return of({ status: AddSsrStatus.TIMEOUT, errorMessage: 'Timeout' });
    }

    // 2. Look for failed ssrs in the response before mapping other errors
    if (err.error?.failedSsrs?.length > 0) {
      return of({
        status: AddSsrStatus.SYSTEM_FAILURE,
        failedToAddSsrs: this.getSsrsDetails(ssrDetails, err.error.failedSsrs),
        errorMessage: 'Request failed, none of the SSRs were added to the reservation',
      });
    }

    // 3. Check for other errors
    const errorMessage = JSON.stringify(err.error);
    if (errorMessage.toLowerCase().includes('password decryption error')) {
      return of({ status: AddSsrStatus.PASSWORD_DECRYPTION_ERROR, errorMessage: 'password decryption error' });
    }
    return of({
      status: AddSsrStatus.SYSTEM_FAILURE,
      errorMessage: `Request failed, none of the SSRs were added to the reservation. error: ${errorMessage}`,
    });
  }

  private mapBatchDeleteSsrsErrorResponse(ssrIds: string[], errorMessage: string): Observable<BatchDeleteSsrResponse> {
    const batchDeleteSsrResponseDetails: BatchDeleteSsrResponseDetail[] = [];
    ssrIds.forEach(id => batchDeleteSsrResponseDetails.push({ ssrId: id, isSuccessful: false, message: errorMessage }));
    return of({ ssrs: batchDeleteSsrResponseDetails } as BatchDeleteSsrResponse);
  }
}
