import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { ReservationLookupStatus } from '../../../dtos/response/reservation-response/reservation-lookup-status';
import { GetVcrType } from '../../../models/get-vcr-type';
import { MessageKey } from '../../../models/message/message-key';
import { setCallerName } from '../../../state/actions';
import { RootState } from '../../../state/state';
import { ReservationUseCase } from '../../../use-cases/reservation.use-case';
import { loadCardsOnFile } from '../../card-on-file-service/state/card-on-file-service.actions';
import { getAncillaryTicketDetails, getTicketDetails, loadVcr } from '../../ticket-service/state/ticket-service.actions';
import { ReservationService } from '../reservation.service';
import {
  cancelReservation,
  cancelReservationComplete,
  createPlaceHolderReservation,
  createPlaceHolderReservationComplete,
  initialLoadReservation,
  loadReservation,
  loadReservationComplete,
  loadReservationFromRoute,
  loadReservationForInitialBooking,
  removeReservation,
  setPassengerLoyalty,
  setTicketNumbers,
  syncPassengerLoyalty,
  syncTicketNumbers,
  removeRemarks,
  removeRemarksSucceeded,
  removeRemarksFailed,
  addRemark,
  addRemarkFailed,
  addRemarkSucceeded,
} from './reservation-service.actions';
import {
  getAssociationRevalError,
  getPlaceholderReservation,
  getRoutedConfirmationCode,
  getTicketNumbers,
} from './reservation-service.selectors';
import { addReservationToSearchHistory } from '../../reservation-search-history-service/state/reservation-search-history.actions';
import { addStackedMessage, resetGlobalJetStreamMessages, resetStackedLongMessages } from '../../message-service/state/message.actions';
import { of } from 'rxjs';
import { ReservationMessageConverter } from '../../../utils/message-converters/reservation/reservation-message-converter';
import { clearPreOrderedMeals } from '../../preorder-meal-service/state/preorder-meal-service.actions';
import { ReservationCreationResponse } from '../../../dtos/response/reservation-creation-response/reservation-creation-response';
import { ReservationCancelResponse } from '../../../dtos/response/reservation-response/reservation-cancel-response';
import { RemarksAddRemoveResponse } from '../../../dtos/response/reservation-response/remarks-add-remove-response';
import { AddDeleteRemarkStatus } from '../../../dtos/response/remarks-response/add-delete-remark-status';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { loadFlightStatus } from '../../flight-service/state/flight-service.actions';
import { initializePasengerFormsState } from '../../../shared/passenger-forms/state/passenger-forms.actions';

@Injectable()
export class ReservationServiceEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private reservationService: ReservationService,
    private reservationUseCase: ReservationUseCase,
    private reservationMessageConverter: ReservationMessageConverter
  ) {}

  initialLoadReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initialLoadReservation),
      withLatestFrom(
        this.store.select(getRoutedConfirmationCode),
        this.store.select(getAssociationRevalError),
        this.store.select(getPlaceholderReservation)
      ),
      exhaustMap(([action, routedConfirmationCode, associationRevalError, placeholderReservation]) => {
        this.handleInitialMessages(routedConfirmationCode, action.confirmationCode, associationRevalError, placeholderReservation);

        this.reservationUseCase.clearReservation();

        this.store.dispatch(removeReservation(routedConfirmationCode));
        this.store.dispatch(clearPreOrderedMeals());
        this.store.dispatch(initializePasengerFormsState());

        return this.reservationService.lookup(action.confirmationCode).pipe(
          map((reservationResponse) => {
            if (reservationResponse.status === ReservationLookupStatus.SUCCESS) {
              // Add the loaded reservation to the search history
              this.store.dispatch(addReservationToSearchHistory({ reservation: reservationResponse?.reservation }));

              // Set the caller name in the store if there are passengers
              if (reservationResponse?.reservation?.passengers?.length > 0) {
                this.store.dispatch(
                  setCallerName(
                    `${reservationResponse.reservation.passengers[0].firstName} ${reservationResponse.reservation.passengers[0].lastName}`
                  )
                );
              }

              // Load the cards on file associated to any mileage plan numbers found on the reservation
              this.store.dispatch(loadCardsOnFile());

              // Load the flight status for each segment
              this.loadSegmentFlightLegInfo(reservationResponse?.reservation?.allSegments);

              if (reservationResponse?.reservation?.ticketNumbers?.length > 0) {
                // Grab all of the tickets including VOID tickets since the below calls will handle displays
                const ticketNumbers = reservationResponse.reservation.ticketNumbers?.map((ticketNumber) => ticketNumber.ticketNumber);
                this.store.dispatch(loadVcr(ticketNumbers, false, GetVcrType.RESERVATION, false));
                this.store.dispatch(getTicketDetails(ticketNumbers));
              }
              if (reservationResponse?.reservation?.ancillaryTicketNumbers?.length > 0) {
                this.store.dispatch(getAncillaryTicketDetails(reservationResponse.reservation.ancillaryTicketNumbers));
              }
            }

            return loadReservationComplete(reservationResponse);
          })
        );
      })
    )
  );

  getReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReservation),
      exhaustMap((action) => {
        return this.reservationService.lookup(action.confirmationCode).pipe(
          map((reservationResponse) => {
            return loadReservationComplete(reservationResponse);
          })
        );
      })
    )
  );

  loadReservationFromRoute$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReservationFromRoute),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      exhaustMap(([action, routedConfirmationCode]) => {
        if (routedConfirmationCode !== null && routedConfirmationCode !== undefined) {
          return this.reservationService.lookup(routedConfirmationCode).pipe(
            map((reservationResponse) => {
              return loadReservationComplete(reservationResponse);
            })
          );
        }
        return of(loadReservationComplete(null));
      })
    )
  );

  loadReservationForInitialBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadReservationForInitialBooking),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      exhaustMap(([action, routedConfirmationCode]) => {
        if (routedConfirmationCode !== null && routedConfirmationCode !== undefined) {
          return this.reservationService.lookup(routedConfirmationCode, true, true).pipe(
            map((reservationResponse) => {
              reservationResponse = this.reservationMessageConverter.convertInitialBookingReservationLookupMessages(reservationResponse);
              // Set the caller name in the store if there are passengers
              if (reservationResponse?.reservation?.passengers?.length > 0) {
                this.store.dispatch(
                  setCallerName(
                    `${reservationResponse.reservation.passengers[0].firstName} ${reservationResponse.reservation.passengers[0].lastName}`
                  )
                );
              }
              return loadReservationComplete(reservationResponse);
            })
          );
        }
        return of(loadReservationComplete(null));
      })
    )
  );

  cancelReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelReservation),
      exhaustMap((action) => {
        return this.reservationService.cancelReservation(action.confirmationCode).pipe(
          map((reservationCancelResponse) => {
            this.handleCancelReservationMessages(reservationCancelResponse);
            return cancelReservationComplete(reservationCancelResponse);
          })
        );
      })
    )
  );

  syncPassengerLoyalty$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncPassengerLoyalty),
      exhaustMap((action) => {
        return this.reservationService.lookup(action.confirmationCode, true).pipe(
          map((reservationResponse) => {
            const loyalty =
              reservationResponse.reservation?.passengers?.find((passenger) => passenger.hashId === action.passengerHashId)?.loyalty ??
              null;
            return setPassengerLoyalty(action.confirmationCode, action.passengerHashId, loyalty);
          })
        );
      })
    )
  );

  /**
   * When a syncTicketNumbers action is dispatched, call the reservation service, take the ticket
   * numbers from the response and dispatch a new action to update the ticket number is the store
   */
  syncTicketNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(syncTicketNumbers),
      exhaustMap((action) => {
        return this.reservationService.lookup(action.confirmationCode, false).pipe(
          map((reservationResponse) => {
            const ticketNumbers = reservationResponse.reservation?.ticketNumbers ?? [];
            // release the cached result from getTicketNumbers, this is so that even if the ticket numbers do no change
            // RAIN still reacts to the new result
            getTicketNumbers.release();
            return setTicketNumbers(action.confirmationCode, ticketNumbers);
          })
        );
      })
    )
  );

  /**
   * Create a placeholder reservation, stored in a separate part of the state from normal reservations
   */
  createPlaceHolderReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createPlaceHolderReservation),
      exhaustMap((action) => {
        return this.reservationService.createFromCoupons(action.reservationFromCoupons).pipe(
          map((reservationResponse) => {
            return createPlaceHolderReservationComplete(reservationResponse);
          })
        );
      })
    )
  );

  addRemark$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addRemark),
      exhaustMap((action) => {
        return this.reservationService.addRemark(action.confirmationCode, action.addRemarkRequest).pipe(
          map((remarksAddRemoveResponse: RemarksAddRemoveResponse) => {
            if(remarksAddRemoveResponse.status === AddDeleteRemarkStatus.SUCCESS) {
              return addRemarkSucceeded(action.confirmationCode, remarksAddRemoveResponse);
            }
            else {
              return addRemarkFailed(remarksAddRemoveResponse.status, remarksAddRemoveResponse.errorMessage);
            }
          })
        );
      })
    )
  );

  removeRemarks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeRemarks),
      exhaustMap((action) => {
        return this.reservationService.removeRemarks(action.confirmationCode, action.remarkIds).pipe(
          map((result: RemarksAddRemoveResponse) => {
            if(result.status === AddDeleteRemarkStatus.SUCCESS) {
              return removeRemarksSucceeded(action.confirmationCode, action.remarkIds, result);
            }
            else {
              return removeRemarksFailed(result.status, result.errorMessage);
            }
          })
        );
      })
    )
  );

  private handleInitialMessages(
    routedConfirmationCode: string,
    actionConfirmationCode: string,
    associationRevalError: boolean,
    placeholderReservation: ReservationCreationResponse
  ) {
    if (routedConfirmationCode !== actionConfirmationCode) {
      this.store.dispatch(resetStackedLongMessages());
    }
    if (associationRevalError) {
      this.store.dispatch(resetStackedLongMessages());
      this.store.dispatch(addStackedMessage(MessageKey.MANUAL_ASSOCIATION_REVAL_FAILURE));
    } else {
      if (placeholderReservation?.reservation?.confirmationCode === actionConfirmationCode) {
        this.store.dispatch(resetStackedLongMessages());
        this.store.dispatch(addStackedMessage(MessageKey.MANUAL_ASSOCIATION_SUCCESS));
      }
    }
  }

  private handleCancelReservationMessages(reservationCancelResponse: ReservationCancelResponse) {
    switch (reservationCancelResponse.content.toLowerCase()) {
      case 'no itinerary':
      case 'success':
        this.store.dispatch(resetGlobalJetStreamMessages());
        break;
      default:
        break;
    }
  }

  private loadSegmentFlightLegInfo(allSegments: Segment[]) {
    if(allSegments) {
      allSegments.forEach(segment => {
        this.store.dispatch(loadFlightStatus(
          segment.operatedByAirlineCode,
          segment.operatingAirlineFlightNumber,
          segment.departureDateTime));
      });
    }
  }
}

