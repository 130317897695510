import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, take, timeout } from 'rxjs/operators';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { SecureFlightInfo } from '../../dtos/request/secure-flight-info/secure-flight-info';
import { SecureFlightInfoResponse } from '../../dtos/response/secure-flight-info/secure-flight-info-response';
import { Store } from '@ngrx/store';
import { RootState } from '../../state/state';
import { loadReservation } from '../reservation-service/state/reservation-service.actions';
import { NavigationStart, Router } from '@angular/router';
import { SecureFlightInfoAddStatus } from '../../dtos/response/secure-flight-info/secure-flight-info-add-status';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { isInvalidSabreUserIdAndPass, isPasswordDecryptionError } from '../../utils/error-helper';

export interface SecureFlightInfoServiceAPI {
  addSecureFlightInfo(request: SecureFlightInfo, confirmationCode: string): Observable<SecureFlightInfoResponse>;
  removeKtnRedressNumbers(confirmationCode: string, passengerHashId: string): Observable<SecureFlightInfoResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class SecureFlightInfoService implements SecureFlightInfoServiceAPI {
  constructor(
    private store: Store<RootState>,
    private router: Router,
    private http: HttpClient,
    private eventService: GlobalEventService
  ) {}

  addSecureFlightInfo(request: SecureFlightInfo, confirmationCode: string): Observable<SecureFlightInfoResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'false',
      }),
    };

    return this.http.post<SecureFlightInfoResponse>(`api/reservation/${confirmationCode}/secure-flight-info`, request, options).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => response),
      catchError((err) => this.mapError(err))
    );
  }

  /**
   * Remove known traveler and redress numbers of a guest given their passengerHashId
   */
  removeKtnRedressNumbers(confirmationCode: string, passengerHashId: string): Observable<SecureFlightInfoResponse> {
    const options = {
      headers: new HttpHeaders({
        background: 'false',
      }),
    };
    return this.http
      .delete<SecureFlightInfoResponse>(
        `api/reservation/${confirmationCode}/secure-flight-info/ktn-redress/?passengerHashId=${passengerHashId}`,
        options
      )
      .pipe(
        timeout({
          each: TimeoutLimit.LONG,
          with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
        }),
        map((response) => response),
        catchError((err) => this.mapError(err))
      );
  }

  // Extracted this method to the service so that we can manipulate this step in the mock service.
  // We need to be able to manipulate it so that the green check can display in the integration tests.
  refreshReservation(confirmationCode: string): void {
    // We want to refresh the reservation when there is a route change (such as when we change tabs).
    // This will give the user a better experience than after each save.
    this.router.events.pipe(take(1)).subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.store.dispatch(loadReservation(confirmationCode));
      }
    });
  }

  private mapError(err: any): Observable<SecureFlightInfoResponse> {
    if (timeoutError.statusText === err.statusText) {
      this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
      return of({ responseStatus: SecureFlightInfoAddStatus.TIMEOUT });
    }
    if (isPasswordDecryptionError(err?.error) || isInvalidSabreUserIdAndPass(err?.error)) {
      return of({ responseStatus: SecureFlightInfoAddStatus.PASSWORD_DECRYPTION_ERROR });
    }
    else {
      return of({ responseStatus: SecureFlightInfoAddStatus.FAILURE });
    }
  }
}
