import { Action, createReducer, on } from '@ngrx/store';
import { Passenger } from '../../../dtos/response/reservation-response/passenger';
import { ReservationResponse } from '../../../dtos/response/reservation-response/reservation-response';
import { Status } from '../../../models/status';
import {
  loadReservation,
  addPassengerLoyalty,
  createPlaceHolderReservation,
  createPlaceHolderReservationComplete,
  initializeReservationServiceState,
  initialLoadReservation,
  loadReservationComplete,
  removePassengerLoyalty,
  removeReservation,
  replaceReservationSegmentsAndPassengers,
  setAssociationRevalError,
  setPassengerCoupon,
  setPassengerCouponMapCoupons,
  setPassengerCoupons,
  setPassengerKnownTravelerNumber,
  setPassengerLoyalty,
  setPassengerRedressNumber,
  setPassengerSeat,
  setSegmentActionCode,
  setSelectedInvoluntaryChangeOption,
  setTicketNumbers,
  syncPassengerLoyalty,
  syncSeats,
  revertSeats,
  setOriginalClassOfService,
  loadReservationFromRoute,
  cancelReservationComplete,
  cancelReservation,
  cancelReservationReset,
  removePassengerLapInfant,
  setPassengerLapInfant,
  loadReservationForInitialBooking,
  detectFCAASegments,
  removeRemarksSucceeded,
  removeRemarksFailed,
  clearRemoveRemarksStatus,
  setSegmentFlightLegInformation,
  addRemarkSucceeded,
  addRemarkFailed,
  clearAddRemarksStatus,
  addReservationMessage,
} from './reservation-service.actions';
import { initialReservationCancelResponseState, initialReservationServiceState, reservationAdapter } from './reservation-service.state';
import { Segment } from '../../../dtos/response/reservation-response/segment';
import { updateFCAASegments } from '../../../utils/fcaa-util';
import { AddDeleteRemarkStatus } from '../../../dtos/response/remarks-response/add-delete-remark-status';
import { RemarksAddRemoveResponse } from '../../../dtos/response/reservation-response/remarks-add-remove-response';

const featureReducer = createReducer(
  initialReservationServiceState,
  on(loadReservation, (state, { confirmationCode }) => {
    return {
      ...state,
      reservationLookupCrudStatus: Status.LOADING,
    };
  }),
  on(loadReservationFromRoute, (state) => {
    return {
      ...state,
      reservationLookupCrudStatus: Status.LOADING,
    };
  }),
  on(loadReservationForInitialBooking, (state) => {
    return {
      ...state,
      reservationLookupCrudStatus: Status.LOADING,
    };
  }),
  on(initialLoadReservation, (state, { confirmationCode }) => {
    return {
      ...state,
      reservationLookupCrudStatus: Status.LOADING,
    };
  }),
  on(removeReservation, (state, { confirmationCode }) => {
    return {
      ...state,
      reservation: reservationAdapter.removeOne(confirmationCode, state.reservation),
    };
  }),
  on(loadReservationComplete, (state, { reservationResponse }) => {
    return {
      ...state,
      reservationLookupCrudStatus: Status.STABLE,
      reservation: reservationAdapter.setOne(reservationResponse, state.reservation),
    };
  }),
  on(cancelReservation, (state, { confirmationCode }) => {
    return {
      ...state,
      reservationCancelResponse: { status: Status.LOADING, statusCode: null, content: '', confirmationCode },
    };
  }),
  on(cancelReservationComplete, (state, { reservationCancelResponse }) => {
    return {
      ...state,
      reservationCancelResponse,
    };
  }),
  on(cancelReservationReset, (state) => {
    return {
      ...state,
      reservationCancelResponse: initialReservationCancelResponseState,
    };
  }),
  on(addPassengerLoyalty, (state, { confirmationCode, passengerHashId }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (reservationResponse) => updatePassengerLoyaltyStatus(reservationResponse, passengerHashId, Status.UPDATING),
        },
        state.reservation
      ),
    };
  }),
  on(removePassengerLoyalty, (state, { confirmationCode, passengerHashId }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (reservationResponse) => updatePassengerLoyaltyStatus(reservationResponse, passengerHashId, Status.DELETING),
        },
        state.reservation
      ),
    };
  }),
  on(setPassengerLoyalty, (state, { confirmationCode, passengerHashId, loyalty }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (reservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(reservationResponse));
            newReservationResponse.reservation.passengers.find((passenger) => passenger.hashId === passengerHashId).loyalty = loyalty
              ? {
                  ...loyalty,
                }
              : null;
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(syncPassengerLoyalty, (state, { confirmationCode, passengerHashId }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (reservationResponse) => updatePassengerLoyaltyStatus(reservationResponse, passengerHashId, Status.LOADING),
        },
        state.reservation
      ),
    };
  }),
  on(setPassengerRedressNumber, (state, { confirmationCode, passengerHashId, redressNumber }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (reservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(reservationResponse));
            newReservationResponse.reservation.passengers.find(
              (passenger) => passenger.hashId === passengerHashId
            ).secureFlightInfo.redressNumber = redressNumber;
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(setPassengerKnownTravelerNumber, (state, { confirmationCode, passengerHashId, knownTraveler }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (reservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(reservationResponse));
            newReservationResponse.reservation.passengers.find(
              (passenger) => passenger.hashId === passengerHashId
            ).secureFlightInfo.knownTravelerNumber = knownTraveler;
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(replaceReservationSegmentsAndPassengers, (state, { reservation }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: reservation.confirmationCode,
          map: (originalReservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(originalReservationResponse));
            newReservationResponse.reservation.allSegments = reservation.allSegments;
            newReservationResponse.reservation.passengers = reservation.passengers;
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  /**
   * Remove the old placeholder reservation from the store when the process to create a new one is started
   */
  on(createPlaceHolderReservation, (state) => ({ ...state, placeholderReservation: null })),
  /**
   * Store the new placeholder reservation response (success or failure)
   */
  on(createPlaceHolderReservationComplete, (state, { placeholderReservation }) => ({ ...state, placeholderReservation })),
  on(setAssociationRevalError, (state, { associationRevalError }) => ({ ...state, associationRevalError })),

  // Store the involuntary change option we chose from drop down (IRROP or SC)
  on(setSelectedInvoluntaryChangeOption, (state, { selectedInvoluntaryChangeOption }) => ({ ...state, selectedInvoluntaryChangeOption })),
  on(setTicketNumbers, (state, { confirmationCode, ticketNumbers }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (originalReservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(originalReservationResponse));
            newReservationResponse.reservation.ticketNumbers = ticketNumbers;
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(setPassengerCoupons, (state, { confirmationCode, passengerHashId, coupons }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (originalReservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(originalReservationResponse));
            let resPassenger = newReservationResponse.reservation.passengers.find((passenger) => passenger.hashId === passengerHashId);
            if (!resPassenger) {
              resPassenger = getExtraSeatPassenger(passengerHashId, newReservationResponse);
            }
            resPassenger.coupons = coupons;
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(setPassengerCouponMapCoupons, (state, { confirmationCode, passengerHashId, coupons }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (originalReservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(originalReservationResponse));
            const resPassenger = newReservationResponse.reservation.passengers.find((passenger) => passenger.hashId === passengerHashId);
            if (resPassenger) {
              resPassenger.couponMapCoupons = coupons;
            }
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(setPassengerCoupon, (state, { confirmationCode, passengerHashId, coupon, index }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (originalReservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(originalReservationResponse));
            let resPassenger = newReservationResponse.reservation.passengers.find((passenger) => passenger.hashId === passengerHashId);
            if (!resPassenger) {
              resPassenger = getExtraSeatPassenger(passengerHashId, newReservationResponse);
            }
            if (resPassenger && !resPassenger.coupons) {
              resPassenger.coupons = [];
            }
            resPassenger.coupons[index] = coupon;
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(setPassengerSeat, (state, { confirmationCode, passengerHashId, seat, index, segmentHashId }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (originalReservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(originalReservationResponse));
            const resPassengers = getAllReservationPassengers(newReservationResponse);
            const resPassenger = resPassengers.find((passenger) => passenger.hashId === passengerHashId);
            if (!resPassenger.seats) {
              resPassenger.seats = [];
            }
            seat = { ...seat, segmentHashId };
            resPassenger.seats[index] = seat;
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(setSegmentActionCode, (state, { confirmationCode, segmentHashId, actionCode }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (originalReservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(originalReservationResponse));
            const resSegment = newReservationResponse.reservation.allSegments?.find((segment) => segment.hashId === segmentHashId);
            if (resSegment) {
              resSegment.actionCode = actionCode;
            }
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(syncSeats, (state, { confirmationCode }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (originalReservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(originalReservationResponse));
            const resPassengers = getAllReservationPassengers(newReservationResponse);
            resPassengers.forEach((pax) => {
              pax.originalSeats = pax.seats;
            });
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(revertSeats, (state, { confirmationCode }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (originalReservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(originalReservationResponse));
            const resPassengers = getAllReservationPassengers(newReservationResponse);
            resPassengers.forEach((pax) => {
              pax.seats = pax.originalSeats;
            });
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(setOriginalClassOfService, (state, { confirmationCode, coupons }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (originalReservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(originalReservationResponse));
            const updatedSegments: Segment[] = [];
            const firstClassServiceCodes = ['U', 'J', 'C', 'D', 'I'];

            newReservationResponse.reservation.allSegments.forEach((segment) => {
              if (firstClassServiceCodes.includes(segment.serviceClassCode)) {
                updatedSegments.push({
                  ...segment,
                  originalClassOfService: coupons?.filter(
                    (coupon) =>
                      (coupon.status === 'OK' || coupon.status === 'CKIN') &&
                      coupon.airlineCode === segment.marketedByAirlineCode &&
                      coupon.departureAirport === segment.departureAirport &&
                      coupon.arrivalAirport === segment.arrivalAirport &&
                      coupon.flightNumber === segment.operatingAirlineFlightNumber
                  )[0]?.classOfService,
                });
              } else {
                updatedSegments.push(segment);
              }
            });
            newReservationResponse.reservation.allSegments = updatedSegments;
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  /**
   * Completes the savePassenger request to the API to remove the International Documents
   */
  on(setPassengerLapInfant, (state, { confirmationCode, passengerHashId, lapInfant }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (reservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(reservationResponse));
            newReservationResponse.reservation.passengers.find((passenger) => passenger.hashId === passengerHashId).lapInfant = lapInfant;
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  /**
   * Completes the savePassenger request to the API to remove the International Documents
   */
  on(removePassengerLapInfant, (state, { confirmationCode, passengerHashId }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (reservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(reservationResponse));
            newReservationResponse.reservation.passengers.find((passenger) => passenger.hashId === passengerHashId).lapInfant = null;
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(detectFCAASegments, (state, { confirmationCode, coupons }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (resResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(resResponse));
            updateFCAASegments(coupons, newReservationResponse.reservation);
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  }),
  on(setSegmentFlightLegInformation, (state, { confirmationCode, flightInformation }) => {
    if (flightInformation) {
      return {
        ...state,
        reservation: reservationAdapter.mapOne(
          {
            id: confirmationCode,
            map: (resResponse) => {
              const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(resResponse));
              const segment = newReservationResponse.reservation.allSegments.find(
                (seg) => seg.operatingAirlineFlightNumber === flightInformation.operatingFlightNumber
              );
              if (flightInformation.flightLegs.length > 0) {
                segment.flightLegInformation = flightInformation.flightLegs[0];
              }
              return newReservationResponse;
            },
          },
          state.reservation
        ),
      };
    }
    return state;
  }),

  on(initializeReservationServiceState, (state) => ({ ...state, ...initialReservationServiceState })),

  on(removeRemarksSucceeded, (state, { confirmationCode, remarkIds, remarksRemoveResponse }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (reservationResponse) => removeRemarks(remarkIds, reservationResponse),
        },
        state.reservation
      ),
      deleteRemarkStatus: remarksRemoveResponse.status,
    };
  }),

  on(removeRemarksFailed, (state, { status }) => {
    return {
      ...state,
      deleteRemarkStatus: status,
    };
  }),

  on(clearRemoveRemarksStatus, (state) => {
    return {
      ...state,
      deleteRemarkStatus: AddDeleteRemarkStatus.NONE,
    };
  }),

  on(clearAddRemarksStatus, (state) => {
    return {
      ...state,
      addRemarkStatus: AddDeleteRemarkStatus.NONE,
    };
  }),

  on(addRemarkSucceeded, (state, { confirmationCode, remarksAddRemoveResponse }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (reservationResponse) => addRemarks(remarksAddRemoveResponse, reservationResponse),
        },
        state.reservation
      ),
      addRemarkStatus: AddDeleteRemarkStatus.SUCCESS,
    };
  }),

  on(addRemarkFailed, (state, { status, errorMessage }) => {
    return {
      ...state,
      addRemarkStatus: status,
    };
  }),
  on(addReservationMessage, (state, { confirmationCode, messageKey }) => {
    return {
      ...state,
      reservation: reservationAdapter.mapOne(
        {
          id: confirmationCode,
          map: (reservationResponse) => {
            const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(reservationResponse));
            if (!newReservationResponse.reservation.mappedMessageKeys) {
              newReservationResponse.reservation.mappedMessageKeys = [messageKey];
            }
            else {
              newReservationResponse.reservation.mappedMessageKeys.push(messageKey);
            }
            return newReservationResponse;
          },
        },
        state.reservation
      ),
    };
  })
);

export function reservationServiceReducer(state = initialReservationServiceState, action: Action) {
  return featureReducer(state, action);
}

function removeRemarks(remarkIds: number[], reservationResponse: ReservationResponse): ReservationResponse {
  const ids = remarkIds.map((id) => id.toString());
  const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(reservationResponse));
  const remarks = newReservationResponse.reservation.remarks.filter((remark) => !ids.includes(remark.id));

  newReservationResponse.reservation.remarks = remarks;
  return newReservationResponse;
}

function addRemarks(remarksAddRemoveResponse: RemarksAddRemoveResponse, reservationResponse): ReservationResponse {
  const ids = remarksAddRemoveResponse.remarkIds.map((id) => id.toString());
  const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(reservationResponse));
  for(let i = 0; i < remarksAddRemoveResponse.remarks.length; i++) {
    newReservationResponse.reservation.remarks.push({id: ids[i], type: 'HS', remarkLines: [remarksAddRemoveResponse.remarks[i].toUpperCase()]});
  }

  return newReservationResponse;
}

function updatePassengerLoyaltyStatus(
  reservationResponse: ReservationResponse,
  passengerHashId: string,
  status: Status
): ReservationResponse {
  const newReservationResponse: ReservationResponse = JSON.parse(JSON.stringify(reservationResponse));
  const responsePassenger = newReservationResponse.reservation.passengers.find((passenger) => passenger.hashId === passengerHashId);
  if (!responsePassenger.loyalty) {
    responsePassenger.loyalty = { id: null, number: null, airlineCode: null, tierStatus: null, status };
  } else {
    responsePassenger.loyalty.status = status;
  }
  return newReservationResponse;
}

function getAllReservationPassengers(reservationResponse: ReservationResponse): Passenger[] {
  const extraSeatPassengers = reservationResponse.reservation.passengers
    .map((passenger) => passenger.extraSeatRefs)
    .filter((passenger) => passenger)
    .flat();
  return reservationResponse.reservation.passengers.concat(extraSeatPassengers);
}

function getExtraSeatPassenger(passengerHashId, reservationResponse: ReservationResponse) {
  const allPassengers = getAllReservationPassengers(reservationResponse);
  return allPassengers.find((pax) => pax.hashId === passengerHashId);
}
