import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { defer as observableDefer, Observable, of as observableOf, throwError as observableThrowError, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { ReservationFromCouponsRequest } from '../../dtos/request/reservation-from-coupons-request';
import { ReservationCreationResponse } from '../../dtos/response/reservation-creation-response/reservation-creation-response';
import { ReservationCreationResponseStatus } from '../../dtos/response/reservation-creation-response/reservation-creation-response-status';
import { Reservation } from '../../dtos/response/reservation-response/reservation';
import { ReservationLookupStatus } from '../../dtos/response/reservation-response/reservation-lookup-status';
import { ReservationResponse } from '../../dtos/response/reservation-response/reservation-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { ReservationCancelResponse } from '../../dtos/response/reservation-response/reservation-cancel-response';
import { Status } from '../../models/status';
import { RemarksAddRemoveResponse } from '../../dtos/response/reservation-response/remarks-add-remove-response';
import { AddDeleteRemarkStatus } from '../../dtos/response/remarks-response/add-delete-remark-status';
import { AddRainBookingRemarksRequest } from '../../dtos/request/remarks-request/add-rain-booking-remarks-request';
import { AddRemarkRequest } from '../../dtos/request/add-remark-request';
import { RemarksAddResponse } from '../../dtos/response/reservation-response/remarks-add-response';
import { isInvalidSabreUserIdAndPass, isPasswordDecryptionError } from '../../utils/error-helper';

export interface ReservationServiceAPI {
  lookup(confirmationCode: string, background?: boolean, withPriceQuote?: boolean): Observable<ReservationResponse>;
  createFromCoupons(reservationFromCoupons: ReservationFromCouponsRequest, background?: boolean): Observable<ReservationCreationResponse>;
  cancelReservation(confirmationCode: string): Observable<ReservationCancelResponse>;
  addRemark(confirmationCode: string, addRemarkRequest: AddRemarkRequest): Observable<RemarksAddRemoveResponse>;
  removeRemarks(confirmationCode: string, remarkIds: number[]): Observable<RemarksAddRemoveResponse>;
  addRainBookingRemarks(confirmationCode: string, request: AddRainBookingRemarksRequest): Observable<RemarksAddRemoveResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class ReservationService implements ReservationServiceAPI {
  constructor(private http: HttpClient, private eventService: GlobalEventService) {}
  /** Gets the reservation for a given confirmation code */
  lookup(confirmationCode: string, background: boolean = false, withPriceQuote: boolean = false): Observable<ReservationResponse> {
    const options = {
      headers: new HttpHeaders({ background: background.toString(), withPriceQuote: withPriceQuote.toString() }),
    };
    return this.http.get<Reservation>(`api/reservation/${confirmationCode.toUpperCase()}`, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((reservationData) => {
        return { reservation: reservationData, status: ReservationLookupStatus.SUCCESS, confirmationCode };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ reservation: null, status: ReservationLookupStatus.TIMEOUT, confirmationCode });
        }
        if (404 === err.status) {
          return observableOf({ reservation: null, status: ReservationLookupStatus.DOES_NOT_EXIST, confirmationCode });
        }
        return observableOf({ reservation: null, status: ReservationLookupStatus.SYSTEM_FAILURE, confirmationCode });
      })
    );
  }

  createFromCoupons(
    reservationFromCoupons: ReservationFromCouponsRequest,
    background: boolean = true
  ): Observable<ReservationCreationResponse> {
    const options = {
      headers: new HttpHeaders({
        background: background.toString(),
      }),
    };
    return this.http.post<Reservation>(`api/reservation/from-coupons`, reservationFromCoupons, options).pipe(
      timeout({
        each: TimeoutLimit.LONG,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((newReservation) => {
        return {
          reservation: newReservation,
          status: ReservationCreationResponseStatus.SUCCESS,
        };
      }),
      catchError((err) => {
        switch (true) {
          case timeoutError.statusText === err.statusText:
            this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
            return observableOf({ status: ReservationCreationResponseStatus.TIMEOUT });
          case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase() === 'flight noop for this flight/date'):
            return observableOf({ status: ReservationCreationResponseStatus.NO_OP_DATE });
          case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase() === 'check flight number'):
            return observableOf({ status: ReservationCreationResponseStatus.NO_OP_FLIGHT_NUM });
          case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase() === 'invalid flight number'):
            return observableOf({ status: ReservationCreationResponseStatus.MARKET_EXIT });
          case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase() === 'invalid class'):
            return observableOf({ status: ReservationCreationResponseStatus.INAVLID_CLASS });
          case err.error?.actionResult?.messages?.some((message: string) => message.toLowerCase() === 'invalid board point'):
            return observableOf({ status: ReservationCreationResponseStatus.INVALID_BOARD_POINT });
          case isPasswordDecryptionError(err.error):
          case isInvalidSabreUserIdAndPass(err.error):
            return observableOf({ status: ReservationCreationResponseStatus.PASSWORD_DECRYPTION_ERROR });
          default:
            return observableOf({ status: ReservationCreationResponseStatus.SYSTEM_FAILURE });
        }
      })
    );
  }

  /** Cancels all segments from the reservation for a given confirmation code */
  cancelReservation(confirmationCode: string, background: boolean = false): Observable<ReservationCancelResponse> {
    const options = {
      headers: new HttpHeaders({
        background: background.toString(),
      }),
      responseType: 'text' as 'json',
    };
    return this.http.delete(`api/reservation/${confirmationCode.toUpperCase()}`, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((response) => {
        return {statusCode: 200, content: response, confirmationCode, status: Status.STABLE} as ReservationCancelResponse;
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return observableOf({ statusCode: err.status, content: err.error.ErrorMessage, confirmationCode, status: Status.STABLE });
        }
        return observableOf({ statusCode: err.status, content: err.error, confirmationCode, status: Status.STABLE });
      })
    );
  }

  addRemark(confirmationCode: string, addRemarkRequest: AddRemarkRequest, background: boolean = false):
    Observable<RemarksAddRemoveResponse> {
    const options = {
      headers: new HttpHeaders({
        background: background.toString(),
      }),
    };

    return this.http.post<RemarksAddResponse>(`api/reservation/${confirmationCode.toUpperCase()}/remarks`, addRemarkRequest, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map((r) => {
        return {
          status: AddDeleteRemarkStatus.SUCCESS,
          remarkIds: r.pnrRemarkResponse.remarkIds,
          remarks: r.remarks,
        };
      }),
      catchError((err) => {
        return this.mapRemarksError(err);
      })
    );
  }

  removeRemarks(confirmationCode: string, remarkIds: number[], background: boolean = false): Observable<RemarksAddRemoveResponse> {
    const options = {
      headers: new HttpHeaders({
        background: background.toString(),
      }),
      responseType: 'text' as 'json',
    };

    const remarkIdsString = remarkIds.map(remarkId => `remarkIds=${remarkId}`).join('&');

    return this.http.delete(`api/reservation/${confirmationCode.toUpperCase()}/remarks?${remarkIdsString}`, options).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map(() => {
        return { status: AddDeleteRemarkStatus.SUCCESS };
      }),
      catchError((err) => {
        return this.mapRemarksError(err);
      })
    );
  }

  addRainBookingRemarks(
    confirmationCode: string,
    request: AddRainBookingRemarksRequest,
    background: boolean = false): Observable<RemarksAddRemoveResponse> {
    const options = {
      headers: new HttpHeaders({
        background: background.toString(),
      }),
      responseType: 'text' as 'json',
    };

    return this.http.post(
      `api/reservation/${confirmationCode.toUpperCase()}/remarks/rain-booking-remarks`, request, options
    ).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => observableDefer(() => observableThrowError(() => new HttpErrorResponse(timeoutError)))
      }),
      map(() => {
        return { status: AddDeleteRemarkStatus.SUCCESS };
      }),
      catchError((err) => {
        return this.mapRemarksError(err);
      })
    );
  }

  private mapRemarksError(err: any): Observable<RemarksAddRemoveResponse> {
    if (timeoutError.statusText === err.statusText) {
      this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
      return of({
        status: AddDeleteRemarkStatus.TIMEOUT,
        errorMessage: 'Timeout'
      });
    }
    else if(err.error?.toLowerCase().includes('simultaneous changes')) {
      return of({
        status: AddDeleteRemarkStatus.SIMULATENOUS_CHANGES_ERROR,
        errorMessage: 'simultaneous changes',
      });
    }
    else if(err.error?.toLowerCase().includes('password decryption error')) {
      return of({
        status: AddDeleteRemarkStatus.PASSWORD_DECRYPTION_ERROR,
        errorMessage: 'password decryption error',
      });
    }
    else {
      return of({
        status: AddDeleteRemarkStatus.SYSTEM_FAILURE,
        errorMessage: 'Request failed, remarks were not added/removed',
      });
    }
  }
}
