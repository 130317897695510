import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, defer, map, Observable, throwError as observableThrowError, of, timeout } from 'rxjs';
import { AddTcpRequest } from '../../dtos/request/osi-request/add-tcp-request';
import { OsiStatus } from '../../dtos/response/osi-response/osi-status';
import { AddTcpDetailResponse } from '../../dtos/response/osi-response/add-tcp-detail-response';
import { AddTcpOsiResponse } from '../../dtos/response/osi-response/add-tcp-osi-response';
import { timeoutError } from '../../models/timeout-error';
import { TimeoutLimit } from '../../models/timeout-limit';
import { GlobalEvent, GlobalEventService } from '../global-event-service/global-event.service';
import { AddOsiRequest } from '../../dtos/request/osi-request/add-osi-request';
import { AddOsiResponse } from '../../dtos/response/osi-response/add-osi-response';
import { DeleteOsiRequest } from '../../dtos/request/osi-request/delete-osi-request';
import { DeleteOsiResponse } from '../../dtos/response/osi-response/delete-osi-response';
import { AddTicketNumberOsiRequest } from '../../dtos/request/osi-request/add-ticketnumber-osi-request';
import { AddOptOutOsiRequest } from '../../dtos/request/osi-request/add-opt-out-osi-request';

export interface OsiServiceAPI {
  addOsi(request: AddOsiRequest): Observable<AddOsiResponse>;
  addTcpOsi(request: AddTcpRequest, confirmationCode: string): Observable<AddTcpOsiResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class OsiService implements OsiServiceAPI {
  constructor(private http: HttpClient, private eventService: GlobalEventService) {}

  addOsi(request: AddOsiRequest): Observable<AddOsiResponse> {
    return this.http.post<AddOsiResponse>(`api/reservation/${request.confirmationCode}/osi`, request).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => {
        return { status: OsiStatus.SUCCESS, ...response };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return of({ status: OsiStatus.TIMEOUT, id: null, actionResult: { code: '504', messages: ['Timeout'] } });
        } else if (err.error?.toLowerCase().includes('simultaneous changes')) {
          return of({ status: OsiStatus.SIMULTANEOUS_CHANGES, id: null, actionResult: { code: '500', messages: ['Request failed'] } });
        } else {
          return of({ status: OsiStatus.SYSTEM_FAILURE, id: null, actionResult: { code: '500', messages: ['Request failed'] } });
        }
      })
    );
  }

  addTicketNumberOsi(request: AddTicketNumberOsiRequest): Observable<AddOsiResponse> {
    return this.http.post<AddOsiResponse>(`api/reservation/${request.confirmationCode}/osi/ticket-number`, request).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => {
        return { status: OsiStatus.SUCCESS, ...response };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return of({ status: OsiStatus.TIMEOUT, id: null, actionResult: { code: '504', messages: ['Timeout'] } });
        } else if (err.error?.toLowerCase().includes('simultaneous changes')) {
          return of({ status: OsiStatus.SIMULTANEOUS_CHANGES, id: null, actionResult: { code: '500', messages: ['Request failed'] } });
        } else {
          return of({ status: OsiStatus.SYSTEM_FAILURE, id: null, actionResult: { code: '500', messages: ['Request failed'] } });
        }
      })
    );
  }

  addOptOutOsi(request: AddOptOutOsiRequest): Observable<AddOsiResponse> {
    return this.http.post<AddOsiResponse>(`api/reservation/${request.confirmationCode}/osi/opt-out`, request).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => {
        return { status: OsiStatus.SUCCESS, ...response };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return of({ status: OsiStatus.TIMEOUT, id: null, actionResult: { code: '504', messages: ['Timeout'] } });
        } else if (err.error?.toLowerCase().includes('simultaneous changes')) {
          return of({ status: OsiStatus.SIMULTANEOUS_CHANGES, id: null, actionResult: { code: '500', messages: ['Request failed'] } });
        } else {
          return of({ status: OsiStatus.SYSTEM_FAILURE, id: null, actionResult: { code: '500', messages: ['Request failed'] } });
        }
      })
    );
  }

  addTcpOsi(request: AddTcpRequest): Observable<AddTcpOsiResponse> {
    return this.http.post<AddTcpDetailResponse>(`api/reservation/${request.confirmationCode}/osi/tcps`, request).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => this.mapResponse(response)),
      catchError((err) => this.mapError(err))
    );
  }

  deleteOsi(request: DeleteOsiRequest): Observable<DeleteOsiResponse> {
    return this.http.post<DeleteOsiResponse>(`api/reservation/${request.confirmationCode}/osi/delete`, request.osis).pipe(
      timeout({
        each: TimeoutLimit.SHORT,
        with: () => defer(() => observableThrowError(() => new HttpErrorResponse(timeoutError))),
      }),
      map((response) => {
        let status: OsiStatus;
        if (response.failureOsis?.length === request.osis.length) {
          status = OsiStatus.SYSTEM_FAILURE;
        } else if (response.successOsis?.length === request.osis.length) {
          status = OsiStatus.SUCCESS;
        } else {
          status = OsiStatus.PARTIAL_SUCCESS;
        }
        return { status, ...response };
      }),
      catchError((err) => {
        if (timeoutError.statusText === err.statusText) {
          this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
          return of({
            status: OsiStatus.TIMEOUT,
            successOsis: [],
            failureOsis: request.osis.map((osi) => {
              return { id: osi.id, error: 'Timeout' };
            }),
          });
        }
        else {
          return of({
            status: OsiStatus.SYSTEM_FAILURE,
            successOsis: [],
            failureOsis: request.osis.map((osi) => {
              return { id: osi.id, error: err.statusText };
          }),
        });
      }
    }));
  }

  private mapResponse(response: AddTcpDetailResponse): AddTcpOsiResponse {
    if (response.osis.filter((tcp) => !tcp.isSuccessful)?.length) {
      return {
        status: OsiStatus.PARTIAL_SUCCESS,
        failedToAddTcps: response.osis.filter((tcp) => !tcp.isSuccessful),
        successfullyAddedTcps: response.osis.filter((tcp) => tcp.isSuccessful),
      };
    }
    return {
      status: OsiStatus.SUCCESS,
      successfullyAddedTcps: response.osis,
    };
  }

  private mapError(err: any): Observable<AddTcpOsiResponse> {
    if (timeoutError.statusText === err.statusText) {
      this.eventService.broadcastAjax(GlobalEvent.AJAX_END, err);
      return of({ status: OsiStatus.TIMEOUT, errorMessage: 'Timeout' });
    } else {
      return of({
        status: OsiStatus.SYSTEM_FAILURE,
        errorMessage: 'Request failed, none of the TCP OSIs were added to the reservation',
      });
    }
  }
}
