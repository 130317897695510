/* eslint-disable no-unused-expressions */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { SecureFlightInfoAddStatus } from '../../../dtos/response/secure-flight-info/secure-flight-info-add-status';
import { HttpStatusCode } from '../../../dtos/http-status-code';
import { MessageKey } from '../../../models/message/message-key';
import { Status } from '../../../models/status';
import { RootState } from '../../../state/state';
import {
  loadReservation,
  setPassengerKnownTravelerNumber,
  setPassengerRedressNumber,
} from '../../reservation-service/state/reservation-service.actions';
import { getRoutedConfirmationCode } from '../../reservation-service/state/reservation-service.selectors';
import { SecureFlightInfoService } from '../secure-flight-info.service';
import {
  ktnRedressRemovalComplete,
  removeKtnRedressNumbers,
  secureFlightInfoUpdateComplete,
  setSecureFlightAddSrrStatus,
  submitSecureFlightInfo,
  updateSecureFlightInfoStatus,
} from './secure-flight-info.actions';
import { addStackedMessage } from '../../message-service/state/message.actions';

@Injectable()
export class SecureFlightInfoEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private secureFlightInfoService: SecureFlightInfoService,
  ) {}

  addSecureFlightInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitSecureFlightInfo),
      withLatestFrom(this.store.select(getRoutedConfirmationCode)),
      mergeMap(([{ request }, confirmationCode]) => {
        return this.secureFlightInfoService.addSecureFlightInfo(request, confirmationCode).pipe(
          map((result) => {
            this.store.dispatch(setSecureFlightAddSrrStatus(result.responseStatus));
            this.handleAddSecureFlightInfoErrorMessage(result.responseStatus);
            this.store.dispatch(updateSecureFlightInfoStatus(request.passengerHashId, Status.STABLE));
            this.secureFlightInfoService.refreshReservation(confirmationCode);
            this.store.dispatch(loadReservation(confirmationCode));
            return secureFlightInfoUpdateComplete(result, request.passengerHashId);
          }),
            catchError((err: HttpErrorResponse) => {
            err.error?.actionResult?.messages?.join().includes('CHECK ITINERARY')
              ? this.store.dispatch(
                  addStackedMessage(MessageKey.SECURE_FLIGHT_INFO_NO_ITIN, [err.statusText])
                )
              : this.store.dispatch(
                  addStackedMessage(MessageKey.SECURE_FLIGHT_INFO_FAILURE, [err.statusText])
                );
            this.store.dispatch(updateSecureFlightInfoStatus(request.passengerHashId, Status.STABLE));
            return of(
              secureFlightInfoUpdateComplete(
                {
                  status: err.status,
                },
                request.passengerHashId
              )
            );
          })
        );
      })
    )
  );

  removeKtnRedressNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeKtnRedressNumbers),
      mergeMap((action) => {
        return this.secureFlightInfoService.removeKtnRedressNumbers(action.confirmationCode, action.passengerHashId).pipe(
          map((result) => {
            if(result?.responseStatus){
              // If the response status is not null, then we have a failure
              this.handleAddSecureFlightInfoErrorMessage(result.responseStatus);
            }
            this.store.dispatch(updateSecureFlightInfoStatus(action.passengerHashId, Status.REMOVED_SECURE_FLIGHT_INFO));
            this.store.dispatch(loadReservation(action.confirmationCode));
            this.store.dispatch(setPassengerRedressNumber(action.confirmationCode, action.passengerHashId, null));
            this.store.dispatch(setPassengerKnownTravelerNumber(action.confirmationCode, action.passengerHashId, null));
            return ktnRedressRemovalComplete(result);
          })
        );
      }),
      catchError(() => of(ktnRedressRemovalComplete({ status: HttpStatusCode.INTERNAL_SERVER_ERROR })))
    )
  );

  private handleAddSecureFlightInfoErrorMessage(responseStatus: SecureFlightInfoAddStatus) {
    switch (responseStatus) {
      case SecureFlightInfoAddStatus.SUCCESS:
        break;
      case SecureFlightInfoAddStatus.PARTIAL_SUCCESS:
        this.store.dispatch(addStackedMessage(MessageKey.SECURE_FLIGHT_INFO_PARTIAL_SUCCESS));
        break;
      case SecureFlightInfoAddStatus.TIMEOUT:
        this.store.dispatch(addStackedMessage(MessageKey.SECURE_FLIGHT_INFO_TIMEOUT));
        break;
      case SecureFlightInfoAddStatus.PASSWORD_DECRYPTION_ERROR:
        this.store.dispatch(addStackedMessage(MessageKey.PASSWORD_DECRYPTION_ERROR));
        break;
      default:
        this.store.dispatch(addStackedMessage(MessageKey.SECURE_FLIGHT_INFO_UNKNOWN));
        break;
    }
  }
}
